/**
 * @generated SignedSource<<3b89e0e0fdcecf11ddc256ead84bb901>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MutationStatus = "ok";
export type PasswordChangeMutation$variables = {
  password: string;
};
export type PasswordChangeMutation$data = {
  readonly updateUserPassword: MutationStatus;
};
export type PasswordChangeMutation = {
  response: PasswordChangeMutation$data;
  variables: PasswordChangeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      }
    ],
    "kind": "ScalarField",
    "name": "updateUserPassword",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PasswordChangeMutation",
    "selections": (v1/*: any*/),
    "type": "GeneralMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PasswordChangeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7f9d779862e846197910a49d666a26a0",
    "id": null,
    "metadata": {},
    "name": "PasswordChangeMutation",
    "operationKind": "mutation",
    "text": "mutation PasswordChangeMutation(\n  $password: String!\n) {\n  updateUserPassword(password: $password)\n}\n"
  }
};
})();

(node as any).hash = "8b0e7917d3b3bd051fe6775e1b838d66";

export default node;
