import { j as i } from "../../jsx-runtime-NmMXPjeo.js";
import n from "styled-components";
import l from "../Logomark/index.es.js";
import x, { DropdownHeader as c } from "../Dropdown/index.es.js";
import "react";
import "../../isExhaustive-ua1FyxmG.js";
import "../Theme/themes.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../palettes-foWluF5S.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../Theme/utils.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/index.es.js";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
import "@headlessui/react";
import "../../clsx.m-7d8NmkQq.js";
import "../../Icon-qMvz1KAz.js";
import "../Icon/variants.es.js";
import "../Icon/countryVariants.es.js";
import "../Typography/index.es.js";
import "../AvatarLabelGroup/index.es.js";
import "../Avatar/index.es.js";
import "../../getInitials-mV6BUugk.js";
import "../Avatar/styles.es.js";
import "react-dom";
import "../Dropdown/style.es.js";
import "../Button/index.es.js";
import "../Button/styles.es.js";
import "../../useDisclose-HPUBLHHN.js";
import "../../useAppFloating-h0t839w5.js";
import "@floating-ui/react-dom";
const v = n.div`
  display: flex;
  flex-flow: row;
  
  align-items: center;
  
  gap: 16px;
  
  > *:first-child {
    display: none;
  }
  
  div.spacer {
    flex: 1;
  }
  
  padding: 0 24px;
  
  @media (max-width: 1024px) {
    padding: 12px 8px 12px 16px;
    
    > *:first-child {
      display: unset;
    }
  }
`, ii = (o) => {
  const {
    product: a,
    userLoginEmail: m,
    userDisplayName: r,
    leftAlignedChildren: s,
    rightAlignedChildren: e,
    dropdownChildren: d,
    className: t,
    ...p
  } = o;
  return /* @__PURE__ */ i.jsxs(v, { ...p, className: t, children: [
    /* @__PURE__ */ i.jsx(l, { variant: a }),
    s,
    /* @__PURE__ */ i.jsx("div", { className: "spacer" }),
    e,
    /* @__PURE__ */ i.jsxs(
      x,
      {
        ...p,
        className: t,
        variant: "avatar",
        avatarProps: {
          variant: "initials",
          displayName: r
        },
        children: [
          /* @__PURE__ */ i.jsx(
            c,
            {
              variant: "avatar",
              avatar: {
                text: r,
                supportingText: m,
                avatar: {
                  variant: "initials",
                  displayName: r
                }
              }
            }
          ),
          d
        ]
      }
    )
  ] });
};
export {
  ii as default
};
