import { j as e } from "../../jsx-runtime-NmMXPjeo.js";
import i from "styled-components";
import { NavLink as w } from "react-router-dom";
import { Menu as p, Transition as h, Disclosure as f } from "@headlessui/react";
import { sideBarItemStyles as l } from "./styles.es.js";
import { forwardRef as y } from "react";
import { I as c } from "../../Icon-qMvz1KAz.js";
import "../Icon/countryVariants.es.js";
import "../../styles-YtnfNTLW.js";
import j from "../Typography/index.es.js";
import { r as $, c as k } from "../../polished.esm-wTGEn2h0.js";
import g from "../Logo/index.es.js";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getPaletteBackground as s, getZIndexes as B, getEffects as I, getPaletteForeground as m, getPaletteBase as M, getPaletteBorder as S, getSpacings as v } from "../Theme/utils.es.js";
import { useMediaQuery as F } from "../Theme/hooks.es.js";
import "../CssBaseline/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../Icon/variants.es.js";
import "../../clsx.m-7d8NmkQq.js";
import "../../isExhaustive-ua1FyxmG.js";
import "../Logomark/index.es.js";
const z = i.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 0 0;
  gap: 12px;

  min-width: 0;

  span {
    flex: 1 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  };
`, b = y(function(n, a) {
  const {
    text: o,
    icon: r,
    className: d,
    onClick: x
  } = n;
  return /* @__PURE__ */ e.jsxs(
    z,
    {
      className: d,
      ref: a,
      onClick: x,
      children: [
        r && /* @__PURE__ */ e.jsx(
          c,
          {
            width: 20,
            height: 20,
            ...r
          }
        ),
        /* @__PURE__ */ e.jsx(j, { variant: "text", size: "md", weight: "semibold", as: "span", children: o })
      ]
    }
  );
}), C = i(w)`
  ${l.root}
  
  &:hover {
    ${l.active}
  }
    
  &.active {
    ${l.active}
  }
  
`, ge = (t) => {
  const {
    text: n,
    icon: a,
    ...o
  } = t;
  return /* @__PURE__ */ e.jsx(C, { ...o, children: /* @__PURE__ */ e.jsx(p.Item, { children: ({ close: r }) => /* @__PURE__ */ e.jsx(b, { text: n, icon: a, onClick: r }) }) });
}, P = i.nav`
  width: 100%;
  height: 64px;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 12px 8px;
  box-sizing: border-box;
  
  background-color: ${s("primary.default")};
`, T = i(p.Items)`
  background-color: transparent;
  
  overflow: hidden;
  position: fixed;
  
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${B("drawer")};

  pointer-events: none;

  div.backdrop {
    background-color: ${(t) => $(s("overlay")(t), 0.7)};
    
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    &.animated {
      transition-property: opacity;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
    }

    &.animated-close {
      opacity: 0;
    }
    
    &.animated-open {
      opacity: 1;
    }
  }

  div.content {
    pointer-events: all;
    
    background-color: ${s("primary.default")};

    box-shadow: ${I("shadows.xl")};
    
    position: fixed;
    top: 0;
    left: 0;
    right: 64px;
    bottom: 0;
    
    display: flex;
    flex-flow: column;
    align-items: stretch;
    
    gap: 16px;
    
    &.animated {
      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
    }
    
    &.animated-close {
      left: calc(-100vw + 64px);
      right: 100vw;
    }
    
    &.animated-open {
      left: 0;
      right: 64px;
    }
    
    > *:first-child {
      margin-top: 16px;
      margin-left: 16px;
      margin-right: 16px;
    }
    
    div.main {
      overflow-y: auto;
      
      padding: 4px 8px;
      
      display: flex;
      flex: 1;
      flex-flow: column;
      align-items: stretch;
      
      gap: 4px;
    }
  }
`, N = i(p.Button)`
  margin-left: auto;
  width: 40px;
  height: 40px;
  padding: 8px;

  border-radius: 8px;
  border-width: 0;
  border-style: none;

  color: ${m("secondary.default")};
  background-color: ${s("primary.default")};

  &:hover {
    cursor: pointer;
    color: ${m("secondary.hover")};
    background-color: ${s("primary.hover")};
  }
`, R = i.button`
  pointer-events: auto;

  position: absolute;
  top: 12px;
  right: 8px;
  z-index: 1;

  width: 40px;
  height: 40px;
  padding: 8px;

  border-radius: 8px;
  border-width: 0;
  border-style: none;

  color: ${m("white")};
  background-color: ${M("transparent")};

  &:hover {
    cursor: pointer;
    color: ${m("white")};
    background-color: ${(t) => k(0.9, s("primary.default")(t))};
  }

  > svg {
    opacity: 0.7;
  }
`, L = i.nav`
  width: 312px;
  height: 100vh;

  background-color: ${s("primary.default")};
  
  display: flex;
  flex-flow: column;
  
  overflow: hidden;
  
  > *:first-child {
    padding: 32px 20px 24px 20px;
  }

  div.main {
    overflow-y: auto;

    padding: 4px 16px;

    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: stretch;

    gap: 4px;
  }
`, u = i.div`
  border-top: 1px solid ${S("secondary")};
  padding: ${v("xl")};
`, ue = (t) => {
  const {
    logo: n,
    children: a,
    footer: o,
    ...r
  } = t;
  return F("mobile") ? /* @__PURE__ */ e.jsx(P, { ...r, children: /* @__PURE__ */ e.jsx(p, { children: ({ close: x }) => /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
    /* @__PURE__ */ e.jsx(N, { children: /* @__PURE__ */ e.jsx(c, { variant: "menu" }) }),
    /* @__PURE__ */ e.jsxs(h, { as: T, appear: !0, children: [
      /* @__PURE__ */ e.jsx(R, { onClick: x, children: /* @__PURE__ */ e.jsx(c, { variant: "close" }) }),
      /* @__PURE__ */ e.jsx(
        h.Child,
        {
          className: "backdrop",
          enter: "animated",
          enterFrom: "animated-close",
          enterTo: "animated-open",
          leave: "animated",
          leaveFrom: "animated-open",
          leaveTo: "animated-close"
        }
      ),
      /* @__PURE__ */ e.jsxs(
        h.Child,
        {
          className: "content",
          enter: "animated",
          enterFrom: "animated-close",
          enterTo: "animated-open",
          leave: "animated",
          leaveFrom: "animated-open",
          leaveTo: "animated-close",
          children: [
            /* @__PURE__ */ e.jsx(g, { variant: n }),
            /* @__PURE__ */ e.jsx("div", { className: "main", children: a }),
            o && /* @__PURE__ */ e.jsx(u, { children: o })
          ]
        }
      )
    ] })
  ] }) }) }) : /* @__PURE__ */ e.jsxs(L, { ...r, children: [
    /* @__PURE__ */ e.jsx(g, { variant: n }),
    /* @__PURE__ */ e.jsx("div", { className: "main", children: /* @__PURE__ */ e.jsx(p, { children: a }) }),
    o && /* @__PURE__ */ e.jsx(u, { children: o })
  ] });
}, A = i(f.Button)`
  ${l.root}
  
  &:hover {
    ${l.active}
  }
  
  ${(t) => t.$isActive ? l.active : ""}
`, D = i(f.Panel)`
  padding-left: ${v("4xl")};
`, ve = (t) => {
  const {
    text: n,
    icon: a,
    children: o,
    defaultOpen: r
  } = t;
  return /* @__PURE__ */ e.jsx(f, { defaultOpen: r, children: (d) => /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
    /* @__PURE__ */ e.jsxs(
      A,
      {
        $isActive: d.open,
        children: [
          /* @__PURE__ */ e.jsx(
            b,
            {
              text: n,
              icon: a
            }
          ),
          /* @__PURE__ */ e.jsx(
            c,
            {
              variant: d.open ? "chevron-up" : "chevron-down",
              height: 20,
              width: 20
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ e.jsx(D, { children: o })
  ] }) });
};
export {
  ge as SideBarItem,
  b as SideBarLabel,
  ve as SideBarParentItem,
  ue as default
};
