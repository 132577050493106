import { css as t } from "styled-components";
import { r as e } from "../../polished.esm-wTGEn2h0.js";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getSpacings as l, getPaletteText as i, getPaletteColors as r, getPaletteBorder as c, getPaletteBackground as a, getPaletteBase as d } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-NmMXPjeo.js";
import "../CssBaseline/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
const C = {
  xs: t`
    padding: 7px ${l("lg")};
    gap: ${l("xs")};
  `,
  sm: t`
    padding: ${l("md")} ${l("lg")};
    gap: ${l("xs")};
  `,
  md: t`
    padding: 10px 14px;
    gap: ${l("xs")};
  `,
  lg: t`
    padding: 10px ${l("xl")};
    gap: ${l("sm")};
  `,
  xl: t`
    padding: ${l("lg")} 18px;
    gap: ${l("sm")};
  `,
  "2xl": t`
    padding: ${l("xl")} 22px;
    gap: 10px;
  `
}, T = {
  xs: t`
    height: 16px;
    width: 16px;
  `,
  sm: t`
    height: 20px;
    width: 20px;
  `,
  md: t`
    height: 20px;
    width: 20px;
  `,
  lg: t`
    height: 20px;
    width: 20px;
  `,
  xl: t`
    height: 20px;
    width: 20px;
  `,
  "2xl": t`
    height: 24px;
    width: 24px;
  `
}, j = {
  contained: t`
    color: ${i("primary.brand")};
    border: 1px solid ${(o) => r(o.$color)(o)[600]};
    background-color: ${(o) => r(o.$color)(o)[600]};

    &:hover, &:active {
      border: 1px solid ${(o) => r(o.$color)(o)[700]};
      background-color: ${(o) => r(o.$color)(o)[700]};
    }

    &:focus {
      box-shadow: 0px 0px 0px 4px ${(o) => e(r(o.$color)(o)[400], 0.24)};
    }

    &:disabled {
      border-color: ${c("disabled.subtle")};
      background-color: ${a("disabled.default")};
    }
  `,
  outlined: t`
    color: ${(o) => r(o.$color)(o)[700]};
    background-color: ${d("white")};
    border: 1px solid ${(o) => r(o.$color)(o)[300]};
    
    &:hover, &:active {
      background-color: ${(o) => r(o.$color)(o)[50]};
    }

    &:focus {
      box-shadow: 0px 0px 0px 4px ${(o) => e(r(o.$color)(o)[400], 0.24)};
    }

    &:disabled {
      border-color: ${c("disabled.subtle")};
      background-color: ${a("disabled.default")};
    }
  `,
  text: t`
    border: none;
    padding: 0;

    color: ${(o) => r(o.$color)(o)[700]};
    background-color: ${d("transparent")};

    &:hover, &:active {
      color: ${(o) => r(o.$color)(o)[800]};
    }

    &:focus {
      color: ${(o) => r(o.$color)(o)[800]};
    }
    
  `,
  ghost: t`
    border: none;
    
    color: ${(o) => r(o.$color)(o)[700]};
    background-color: ${d("transparent")};

    &:hover, &:active {
      color: ${(o) => r(o.$color)(o)[800]};
      background-color: ${(o) => r(o.$color)(o)[50]};
    }

    &:focus {
      background-color: ${(o) => r(o.$color)(o)[25]};
      box-shadow: 0px 0px 0px 4px ${(o) => e(r(o.$color)(o)[400], 0.24)};
    }
    
    &:disabled {
      background-color: ${d("transparent")};
    }
  `
};
export {
  T as iconSizeStyles,
  C as sizeStyles,
  j as variantStyles
};
