import { css as o } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getPaletteColors as p, getPaletteForeground as i, getPaletteBorder as r, getSpacings as e } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-NmMXPjeo.js";
import "../CssBaseline/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
const P = {
  primary: o`
    padding: 15px 7px 7px 7px;
    
    > svg {
      width: 18px;
      height: 18px;
    }
    
    color: ${(t) => p(t.$color)(t)[600]};
  `,
  gray: o`
    padding: 16px 8px 8px 8px;
    
    > svg {
      width: 16px;
      height: 16px;
    }
    
    color: ${i("secondary.default")};
  `,
  solid: o`
    padding: 16px 8px 8px 8px;
    
    > svg {
      width: 16px;
      height: 16px;
    }
    
    color: ${i("white")};
  `
}, V = {
  primary: o`
    color: ${r("primary")};
  `,
  gray: o``,
  solid: o`
    color: ${(t) => p(t.$color)(t)[600]};
  `
}, j = {
  primary: o`
    border-radius: 2px;
    position: absolute;
    z-index: 1;
    left: -7px;
    bottom: 8px;
    
    padding: ${e("xxs")} 3px;
    color: ${i("white")};
    background-color: ${(t) => p(t.$color)(t)[600]};
  `,
  gray: o`
    position: absolute;
    z-index: 1;
    width: 100%;
    justify-content: center;
    padding-top: 23px;
    padding-bottom: 6px;
    
    color: ${i("secondary.default")};
  `,
  solid: o`
    position: absolute;
    z-index: 1;
    width: 100%;
    justify-content: center;
    padding-top: 23px;
    padding-bottom: 6px;
    
    color: ${i("white")};
  `
};
export {
  j as extensionVariantStyles,
  P as iconVariantStyles,
  V as pageIconVariantStyles
};
