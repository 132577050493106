import { j as o } from "../../jsx-runtime-NmMXPjeo.js";
import "../../styles-YtnfNTLW.js";
import s from "../Typography/index.es.js";
import l from "../Button/index.es.js";
import { I as m } from "../../Icon-qMvz1KAz.js";
import "react";
import "../Icon/countryVariants.es.js";
import a from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getWidths as p, getSpacings as r } from "../Theme/utils.es.js";
import "../CssBaseline/index.es.js";
import "../../ErrorFeedbackContainer-zSGLd02a.js";
import { SomethingWentWrongFeedback as g, PageNotFoundFeedback as d } from "../feedbacks/index.es.js";
import "../../clsx.m-7d8NmkQq.js";
import "../Button/styles.es.js";
import "../../polished.esm-wTGEn2h0.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../Icon/variants.es.js";
const x = a.div`
  display: flex;
  height: ${p("xl")};
  padding: 0 ${r("3xl")};
  flex-direction: column;
  align-items: center;
  align-self: center;

  gap: ${r("4xl")};
`, h = a.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${r("6xl")} 48px;
  flex: 1 0 0;
  align-self: stretch;
`, f = a.div`
  display: flex;
  align-items: flex-start;
  gap: ${r("lg")};
`, c = (e) => {
  const {
    className: i,
    actions: t,
    feedback: n
  } = e;
  return /* @__PURE__ */ o.jsx(x, { className: i, children: /* @__PURE__ */ o.jsxs(h, { children: [
    n,
    /* @__PURE__ */ o.jsx(f, { children: t })
  ] }) });
}, M = (e) => {
  const {
    className: i,
    onGoBackClick: t,
    onGotoLoginClick: n
  } = e;
  return /* @__PURE__ */ o.jsx(
    c,
    {
      className: i,
      feedback: /* @__PURE__ */ o.jsx(
        g,
        {
          message: "The page you are looking for doesn't exist or has been moved."
        }
      ),
      actions: /* @__PURE__ */ o.jsxs(o.Fragment, { children: [
        /* @__PURE__ */ o.jsx(
          l,
          {
            size: "2xl",
            startAdornment: /* @__PURE__ */ o.jsx(m, { variant: "arrow-left" }),
            variant: "outlined",
            color: "gray",
            onClick: t,
            children: /* @__PURE__ */ o.jsx(s, { size: "lg", weight: "semibold", color: "secondary", children: "Go back" })
          }
        ),
        /* @__PURE__ */ o.jsx(
          l,
          {
            size: "2xl",
            onClick: n,
            children: /* @__PURE__ */ o.jsx(s, { size: "lg", weight: "semibold", color: "white", children: "Go to log in" })
          }
        )
      ] })
    }
  );
}, O = (e) => {
  const {
    className: i,
    onRefreshClick: t
  } = e;
  return /* @__PURE__ */ o.jsx(
    c,
    {
      className: i,
      feedback: /* @__PURE__ */ o.jsx(
        d,
        {
          message: "We are working on fixing the problem. Please try again."
        }
      ),
      actions: /* @__PURE__ */ o.jsx(
        l,
        {
          size: "2xl",
          onClick: t,
          children: /* @__PURE__ */ o.jsx(s, { size: "lg", weight: "semibold", color: "white", children: "Refresh page" })
        }
      )
    }
  );
};
export {
  M as PageNotFound,
  O as SomethingWentWrong
};
