import { j as r } from "../../jsx-runtime-NmMXPjeo.js";
import { createGlobalStyle as a } from "styled-components";
import m from "./normalize.es.js";
import { d as s } from "../../styles-YtnfNTLW.js";
import { getPaletteText as l, getPaletteBackground as i } from "../Theme/utils.es.js";
import "react";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
const n = a`
  ${m}
  ${s}
  body {
    font-family: "Inter", sans-serif;

    color: ${l("default")};
    background: ${i("primary.default")};

    margin: 0;
    padding: 0;
  }
  ${(o) => {
  var t, e;
  return (e = (t = o.theme.components) == null ? void 0 : t.CssBaseline) == null ? void 0 : e.styleOverrides;
}}
`, $ = () => /* @__PURE__ */ r.jsx(n, {});
export {
  $ as default,
  m as normalize
};
