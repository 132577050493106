import { css as i } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getRadii as o, getSpacings as d, getPaletteColors as t, getPaletteBase as p, getPaletteForeground as g, getEffects as h } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-NmMXPjeo.js";
import "../CssBaseline/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
const B = {
  sm: i`
    width: 32px;
    height: 32px;
    
    > svg {
      width: 16px;
      height: 16px;
    }
  `,
  md: i`
    width: 40px;
    height: 40px;
    
    > svg {
      width: 20px;
      height: 20px;
    }
  `,
  lg: i`
    width: 48px;
    height: 48px;
    
    > svg {
      width: 24px;
      height: 24px;
    }
  `,
  xl: i`
    width: 56px;
    height: 56px;
    
    > svg {
      width: 28px;
      height: 28px;
    }
  `,
  xxl: i`
    width: 72px;
    height: 72px;
    
    > svg {
      width: 32px;
      height: 32px;
    }
  `
}, e = {
  sm: i`
    border-radius: ${o("sm")};
  `,
  md: i`
    border-radius: ${o("md")};
  `,
  lg: i`
    border-radius: ${o("lg")};
  `,
  xl: i`
    border-radius: ${o("lg")};
  `,
  xxl: i`
    border-radius: ${o("2xl")};
  `
}, C = {
  light: i`
    padding: ${d("md")};
    border-radius: ${o("full")};
    color: ${(r) => t(r.$color)(r)[600]};
    background-color: ${(r) => t(r.$color)(r)[100]};
  `,
  dark: i`
    padding: ${d("md")};
    color: ${p("white")};
    background-color: ${(r) => t(r.$color)(r)[600]};
    
    ${(r) => e[r.$size]};
  `,
  glass: i`
    z-index: 1;
    border: 1px solid rgba(255, 255, 255, 0.60);
    background: rgba(255, 255, 255, 0.60);
    backdrop-filter: blur(10.285714149475098px);
    
    padding: ${d("md")};
    color: ${g("white")};
    
    ${(r) => e[r.$size]};
  `,
  modern: i`
    padding: ${d("md")};
    color: ${(r) => t(r.$color)(r)[600]};
    border: 1px solid ${t("gray.200")};
    box-shadow: ${h("shadows.xs")};
    
    ${(r) => e[r.$size]};
  `
};
export {
  e as borderRadiusStyles,
  B as sizeStyles,
  C as variantStyles
};
