import { j as e } from "../../jsx-runtime-NmMXPjeo.js";
import { Menu as x } from "@headlessui/react";
import p from "styled-components";
import { c as b } from "../../clsx.m-7d8NmkQq.js";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getPaletteForeground as k, getSpacings as j, getRadii as z, getPaletteBackground as y, getPaletteBorder as V, getPaletteBase as P } from "../Theme/utils.es.js";
import { useRef as U, useMemo as X } from "react";
import "../CssBaseline/index.es.js";
import { I as Y } from "../../Icon-qMvz1KAz.js";
import "../Icon/countryVariants.es.js";
import "../../styles-YtnfNTLW.js";
import g from "../Typography/index.es.js";
import { i as E } from "../../isExhaustive-ua1FyxmG.js";
import Z from "../AvatarLabelGroup/index.es.js";
import { createPortal as M } from "react-dom";
import _ from "../Avatar/index.es.js";
import { Items as W } from "./style.es.js";
import ee from "../Button/index.es.js";
import { u as te } from "../../useDisclose-HPUBLHHN.js";
import { u as A } from "../../useAppFloating-h0t839w5.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../Icon/variants.es.js";
import "../../getInitials-mV6BUugk.js";
import "../Avatar/styles.es.js";
import "../Button/styles.es.js";
import "@floating-ui/react-dom";
const re = p.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
`, oe = p.div`
  flex: 1;
  text-align: left;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`, B = p(Y)`
  color: ${k("quarterary.default")};
`, H = (r) => {
  const {
    text: o,
    shortcut: t,
    leftIcon: n,
    rightIcon: s,
    className: c
  } = r, i = {
    width: 16,
    height: 16
  };
  return /* @__PURE__ */ e.jsxs(re, { className: c, children: [
    n && /* @__PURE__ */ e.jsx(
      B,
      {
        ...i,
        ...n
      }
    ),
    /* @__PURE__ */ e.jsxs(oe, { children: [
      /* @__PURE__ */ e.jsx(g, { variant: "text", size: "sm", weight: "medium", as: "span", className: "text", children: o }),
      t && /* @__PURE__ */ e.jsx(g, { variant: "text", size: "xs", weight: "regular", as: "span", color: "tertiary", children: t })
    ] }),
    s && /* @__PURE__ */ e.jsx(
      B,
      {
        ...i,
        ...s
      }
    )
  ] });
}, ne = p.button`
  margin: ${j("xxs")} ${j("sm")};

  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: ${z("sm")};

  padding: 9px 10px;

  border: none;

  color: ${k("quarterary.default")};

  background-color: ${y("primary.default")};

  overflow: hidden;

  outline: none;

  &:not(.disabled):hover {
    cursor: pointer;
    background-color: ${y("primary.hover")};
  }
`, D = (r) => {
  const {
    selected: o,
    disabled: t,
    onClick: n,
    className: s,
    ...c
  } = r, i = b(
    {
      selected: o,
      disabled: t
    },
    s
  );
  return /* @__PURE__ */ e.jsx(
    x.Item,
    {
      as: ne,
      disabled: t,
      className: i,
      onClick: n,
      children: /* @__PURE__ */ e.jsx(H, { ...c })
    }
  );
}, se = p.div`
  z-index: 10;
  
  display: flex;
  flex-direction: column;
  
  padding: 4px 0;
    
  &:not(:first-child) {
    border-top: 1px solid ${V("secondary")};
  }
`, ie = (r) => {
  const {
    className: o,
    children: t,
    ...n
  } = r;
  return /* @__PURE__ */ e.jsx(se, { ...n, className: o, children: t });
}, O = p.header`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  padding: 12px 16px;

  border-bottom: 1px solid ${V("secondary")};
`, Qe = (r) => {
  switch (r.variant) {
    case "avatar": {
      const {
        variant: o,
        avatar: {
          size: t,
          ...n
        },
        className: s,
        ...c
      } = r;
      return /* @__PURE__ */ e.jsx(O, { ...c, className: b(`variant-${o}`, s), children: /* @__PURE__ */ e.jsx(Z, { ...n, size: t ?? "md" }) });
    }
    case "text": {
      const {
        variant: o,
        text: t,
        className: n,
        ...s
      } = r;
      return /* @__PURE__ */ e.jsx(O, { ...s, className: b(`variant-${o}`, n), children: /* @__PURE__ */ e.jsx(g, { variant: "text", size: "sm", weight: "semibold", as: "span", children: t }) });
    }
    default:
      E(r);
  }
}, ae = (r) => {
  const {
    currentValue: o,
    selectedValue: t,
    isMultiple: n
  } = r;
  if (!n)
    return t;
  const s = o.split(",").filter((i) => !!i);
  return s.find((i) => i === t) ? [
    ...s
  ].filter((i) => i !== t).join(",") : [
    ...s,
    t
  ].join(",");
}, le = p(ie)`
  &:not(:first-child) {
    border: none;
  }
  .selected {
    svg {
      color: ${k("brand.primary.default")};
    }
  }
`, ce = p.input`
  display: none;
`, de = (r) => {
  const {
    label: o,
    value: t,
    options: n,
    className: s,
    placement: c,
    renderOption: i,
    onChange: h,
    inputRef: f,
    isMultiple: u,
    ...w
  } = r, {
    isOpen: $,
    onClose: I,
    onOpen: T
  } = te(!1), {
    refs: S,
    floatingStyles: q
  } = A({
    floatingOptions: {
      placement: c,
      open: !1
    }
  }), v = U(null), C = X(() => {
    if (u) {
      const a = [
        ...new Set(t.split(","))
      ];
      if (a.length === 1) {
        const [d] = a, m = n.find((N) => N.value === d);
        return m ? `: ${m.label}` : "";
      }
      if (a.length > 1)
        return ` (${a.length})`;
    }
    const l = n.find((a) => a.value === t);
    return l ? `: ${l.label}` : "";
  }, [
    t,
    n
  ]), L = n.length === 0, G = typeof i < "u", J = (l) => u ? t.split(",").findIndex((d) => d === l) !== -1 : l === t, F = (l, a) => {
    var m;
    const d = (m = Object.getOwnPropertyDescriptor(
      HTMLInputElement.prototype,
      "value"
    )) == null ? void 0 : m.set;
    if (v.current && d) {
      const N = ae({
        currentValue: t,
        selectedValue: a,
        isMultiple: u
      });
      d.call(v.current, N);
      const Q = new Event("input", {
        bubbles: !0
      });
      v.current.dispatchEvent(Q);
    }
    u && l.preventDefault();
  }, K = () => {
    $ ? I() : T();
  };
  return /* @__PURE__ */ e.jsxs(x, { as: le, children: [
    /* @__PURE__ */ e.jsx(
      x.Button,
      {
        as: ee,
        className: s,
        ref: S.setReference,
        onClick: K,
        ...w,
        children: typeof o == "string" ? /* @__PURE__ */ e.jsxs(g, { size: "sm", color: "none", weight: "semibold", children: [
          o,
          C
        ] }) : /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
          o,
          /* @__PURE__ */ e.jsx(g, { size: "sm", color: "none", weight: "semibold", children: C })
        ] })
      }
    ),
    /* @__PURE__ */ e.jsx(
      ce,
      {
        ref: (l) => {
          typeof f == "function" ? f(l) : f && (f.current = l), v.current = l;
        },
        value: t,
        onChange: h
      }
    ),
    M(
      /* @__PURE__ */ e.jsx(W, { style: q, ref: S.setFloating, children: L ? /* @__PURE__ */ e.jsx(D, { disabled: !0, text: "No data" }) : /* @__PURE__ */ e.jsx(e.Fragment, { children: n.map(({ label: l, value: a }) => {
        const d = J(a);
        return G ? /* @__PURE__ */ e.jsx(
          x.Item,
          {
            as: "div",
            onClick: (m) => F(m, a),
            children: i({ label: l, value: a, isSelected: d })
          },
          a
        ) : /* @__PURE__ */ e.jsx(
          D,
          {
            text: l,
            selected: d,
            onClick: (m) => F(m, a),
            rightIcon: d ? { variant: "check", color: "red" } : void 0
          },
          a
        );
      }) }) }),
      document.body
    )
  ] });
}, R = p(x.Button)`
  &:not(.disabled) {
    cursor: pointer;
  }

  padding: 0;
  border-style: none;
  background: ${y("primary.default")};

  &.variant-avatar {
    color: ${P("black")};

    &:hover {
      color: ${P("black")};
    }

    outline: none;
  }

  &.variant-select {
    padding: ${j("md")} ${j("lg")};
    border-radius: ${z("sm")};

    &:hover {
      background: ${y("primary.hover")};
    }
  }

  width: ${(r) => r.$isFullWidth ? "100%" : ""};
`, Ue = (r) => {
  const {
    variant: o,
    disabled: t,
    children: n,
    className: s,
    fullWidth: c,
    placement: i = "bottom-end"
  } = r, {
    refs: h,
    floatingStyles: f
  } = A({
    floatingOptions: {
      placement: i
    }
  }), u = b(`variant-${o}`, s), w = () => {
    switch (o) {
      case "avatar": {
        const { size: $, ...I } = r.avatarProps;
        return /* @__PURE__ */ e.jsx(
          R,
          {
            ref: h.setReference,
            className: u,
            disabled: t,
            children: /* @__PURE__ */ e.jsx(_, { size: $ ?? "md", ...I })
          }
        );
      }
      case "select":
        return /* @__PURE__ */ e.jsx(
          R,
          {
            className: u,
            ref: h.setReference,
            $isFullWidth: c,
            disabled: t,
            children: /* @__PURE__ */ e.jsx(H, { ...r.selectProps })
          }
        );
      case "filter":
        return /* @__PURE__ */ e.jsx(de, { placement: i, ...r.filterSelectProps });
      default:
        E(o);
    }
  };
  return /* @__PURE__ */ e.jsxs(x, { children: [
    w(),
    o === "filter" ? void 0 : /* @__PURE__ */ e.jsx(e.Fragment, { children: M(
      /* @__PURE__ */ e.jsx(
        W,
        {
          ref: h.setFloating,
          style: f,
          $isFullWidth: c,
          children: n
        }
      ),
      document.body
    ) })
  ] });
};
export {
  de as DropdownFilterSelect,
  Qe as DropdownHeader,
  D as DropdownItem,
  ie as DropdownItems,
  Ue as default
};
