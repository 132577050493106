import { j as Cr } from "../../jsx-runtime-NmMXPjeo.js";
import { c as ur, g as Rr } from "../../_commonjsHelpers-f3sTPFkQ.js";
import { createContext as Lr, useMemo as qr, useEffect as Dr, useContext as Mr } from "react";
var X = {}, b = {}, ar;
function kr() {
  if (ar)
    return b;
  ar = 1, b.byteLength = o, b.toByteArray = C, b.fromByteArray = y;
  for (var x = [], p = [], w = typeof Uint8Array < "u" ? Uint8Array : Array, E = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/", g = 0, B = E.length; g < B; ++g)
    x[g] = E[g], p[E.charCodeAt(g)] = g;
  p[45] = 62, p[95] = 63;
  function l(s) {
    var c = s.length;
    if (c % 4 > 0)
      throw new Error("Invalid string. Length must be a multiple of 4");
    var m = s.indexOf("=");
    m === -1 && (m = c);
    var F = m === c ? 0 : 4 - m % 4;
    return [m, F];
  }
  function o(s) {
    var c = l(s), m = c[0], F = c[1];
    return (m + F) * 3 / 4 - F;
  }
  function T(s, c, m) {
    return (c + m) * 3 / 4 - m;
  }
  function C(s) {
    var c, m = l(s), F = m[0], _ = m[1], A = new w(T(s, F, _)), R = 0, D = _ > 0 ? F - 4 : F, I;
    for (I = 0; I < D; I += 4)
      c = p[s.charCodeAt(I)] << 18 | p[s.charCodeAt(I + 1)] << 12 | p[s.charCodeAt(I + 2)] << 6 | p[s.charCodeAt(I + 3)], A[R++] = c >> 16 & 255, A[R++] = c >> 8 & 255, A[R++] = c & 255;
    return _ === 2 && (c = p[s.charCodeAt(I)] << 2 | p[s.charCodeAt(I + 1)] >> 4, A[R++] = c & 255), _ === 1 && (c = p[s.charCodeAt(I)] << 10 | p[s.charCodeAt(I + 1)] << 4 | p[s.charCodeAt(I + 2)] >> 2, A[R++] = c >> 8 & 255, A[R++] = c & 255), A;
  }
  function h(s) {
    return x[s >> 18 & 63] + x[s >> 12 & 63] + x[s >> 6 & 63] + x[s & 63];
  }
  function f(s, c, m) {
    for (var F, _ = [], A = c; A < m; A += 3)
      F = (s[A] << 16 & 16711680) + (s[A + 1] << 8 & 65280) + (s[A + 2] & 255), _.push(h(F));
    return _.join("");
  }
  function y(s) {
    for (var c, m = s.length, F = m % 3, _ = [], A = 16383, R = 0, D = m - F; R < D; R += A)
      _.push(f(s, R, R + A > D ? D : R + A));
    return F === 1 ? (c = s[m - 1], _.push(
      x[c >> 2] + x[c << 4 & 63] + "=="
    )) : F === 2 && (c = (s[m - 2] << 8) + s[m - 1], _.push(
      x[c >> 10] + x[c >> 4 & 63] + x[c << 2 & 63] + "="
    )), _.join("");
  }
  return b;
}
var H = {};
/*! ieee754. BSD-3-Clause License. Feross Aboukhadijeh <https://feross.org/opensource> */
var hr;
function Nr() {
  return hr || (hr = 1, H.read = function(x, p, w, E, g) {
    var B, l, o = g * 8 - E - 1, T = (1 << o) - 1, C = T >> 1, h = -7, f = w ? g - 1 : 0, y = w ? -1 : 1, s = x[p + f];
    for (f += y, B = s & (1 << -h) - 1, s >>= -h, h += o; h > 0; B = B * 256 + x[p + f], f += y, h -= 8)
      ;
    for (l = B & (1 << -h) - 1, B >>= -h, h += E; h > 0; l = l * 256 + x[p + f], f += y, h -= 8)
      ;
    if (B === 0)
      B = 1 - C;
    else {
      if (B === T)
        return l ? NaN : (s ? -1 : 1) * (1 / 0);
      l = l + Math.pow(2, E), B = B - C;
    }
    return (s ? -1 : 1) * l * Math.pow(2, B - E);
  }, H.write = function(x, p, w, E, g, B) {
    var l, o, T, C = B * 8 - g - 1, h = (1 << C) - 1, f = h >> 1, y = g === 23 ? Math.pow(2, -24) - Math.pow(2, -77) : 0, s = E ? 0 : B - 1, c = E ? 1 : -1, m = p < 0 || p === 0 && 1 / p < 0 ? 1 : 0;
    for (p = Math.abs(p), isNaN(p) || p === 1 / 0 ? (o = isNaN(p) ? 1 : 0, l = h) : (l = Math.floor(Math.log(p) / Math.LN2), p * (T = Math.pow(2, -l)) < 1 && (l--, T *= 2), l + f >= 1 ? p += y / T : p += y * Math.pow(2, 1 - f), p * T >= 2 && (l++, T /= 2), l + f >= h ? (o = 0, l = h) : l + f >= 1 ? (o = (p * T - 1) * Math.pow(2, g), l = l + f) : (o = p * Math.pow(2, f - 1) * Math.pow(2, g), l = 0)); g >= 8; x[w + s] = o & 255, s += c, o /= 256, g -= 8)
      ;
    for (l = l << g | o, C += g; C > 0; x[w + s] = l & 255, s += c, l /= 256, C -= 8)
      ;
    x[w + s - c] |= m * 128;
  }), H;
}
/*!
 * The buffer module from node.js, for the browser.
 *
 * @author   Feross Aboukhadijeh <https://feross.org>
 * @license  MIT
 */
var sr;
function br() {
  return sr || (sr = 1, function(x) {
    var p = kr(), w = Nr(), E = typeof Symbol == "function" && typeof Symbol.for == "function" ? Symbol.for("nodejs.util.inspect.custom") : null;
    x.Buffer = o, x.SlowBuffer = A, x.INSPECT_MAX_BYTES = 50;
    var g = 2147483647;
    x.kMaxLength = g, o.TYPED_ARRAY_SUPPORT = B(), !o.TYPED_ARRAY_SUPPORT && typeof console < "u" && typeof console.error == "function" && console.error(
      "This browser lacks typed array (Uint8Array) support which is required by `buffer` v5.x. Use `buffer` v4.x if you require old browser support."
    );
    function B() {
      try {
        var t = new Uint8Array(1), r = { foo: function() {
          return 42;
        } };
        return Object.setPrototypeOf(r, Uint8Array.prototype), Object.setPrototypeOf(t, r), t.foo() === 42;
      } catch {
        return !1;
      }
    }
    Object.defineProperty(o.prototype, "parent", {
      enumerable: !0,
      get: function() {
        if (o.isBuffer(this))
          return this.buffer;
      }
    }), Object.defineProperty(o.prototype, "offset", {
      enumerable: !0,
      get: function() {
        if (o.isBuffer(this))
          return this.byteOffset;
      }
    });
    function l(t) {
      if (t > g)
        throw new RangeError('The value "' + t + '" is invalid for option "size"');
      var r = new Uint8Array(t);
      return Object.setPrototypeOf(r, o.prototype), r;
    }
    function o(t, r, e) {
      if (typeof t == "number") {
        if (typeof r == "string")
          throw new TypeError(
            'The "string" argument must be of type string. Received type number'
          );
        return f(t);
      }
      return T(t, r, e);
    }
    o.poolSize = 8192;
    function T(t, r, e) {
      if (typeof t == "string")
        return y(t, r);
      if (ArrayBuffer.isView(t))
        return c(t);
      if (t == null)
        throw new TypeError(
          "The first argument must be one of type string, Buffer, ArrayBuffer, Array, or Array-like Object. Received type " + typeof t
        );
      if (k(t, ArrayBuffer) || t && k(t.buffer, ArrayBuffer) || typeof SharedArrayBuffer < "u" && (k(t, SharedArrayBuffer) || t && k(t.buffer, SharedArrayBuffer)))
        return m(t, r, e);
      if (typeof t == "number")
        throw new TypeError(
          'The "value" argument must not be of type number. Received type number'
        );
      var i = t.valueOf && t.valueOf();
      if (i != null && i !== t)
        return o.from(i, r, e);
      var n = F(t);
      if (n)
        return n;
      if (typeof Symbol < "u" && Symbol.toPrimitive != null && typeof t[Symbol.toPrimitive] == "function")
        return o.from(
          t[Symbol.toPrimitive]("string"),
          r,
          e
        );
      throw new TypeError(
        "The first argument must be one of type string, Buffer, ArrayBuffer, Array, or Array-like Object. Received type " + typeof t
      );
    }
    o.from = function(t, r, e) {
      return T(t, r, e);
    }, Object.setPrototypeOf(o.prototype, Uint8Array.prototype), Object.setPrototypeOf(o, Uint8Array);
    function C(t) {
      if (typeof t != "number")
        throw new TypeError('"size" argument must be of type number');
      if (t < 0)
        throw new RangeError('The value "' + t + '" is invalid for option "size"');
    }
    function h(t, r, e) {
      return C(t), t <= 0 ? l(t) : r !== void 0 ? typeof e == "string" ? l(t).fill(r, e) : l(t).fill(r) : l(t);
    }
    o.alloc = function(t, r, e) {
      return h(t, r, e);
    };
    function f(t) {
      return C(t), l(t < 0 ? 0 : _(t) | 0);
    }
    o.allocUnsafe = function(t) {
      return f(t);
    }, o.allocUnsafeSlow = function(t) {
      return f(t);
    };
    function y(t, r) {
      if ((typeof r != "string" || r === "") && (r = "utf8"), !o.isEncoding(r))
        throw new TypeError("Unknown encoding: " + r);
      var e = R(t, r) | 0, i = l(e), n = i.write(t, r);
      return n !== e && (i = i.slice(0, n)), i;
    }
    function s(t) {
      for (var r = t.length < 0 ? 0 : _(t.length) | 0, e = l(r), i = 0; i < r; i += 1)
        e[i] = t[i] & 255;
      return e;
    }
    function c(t) {
      if (k(t, Uint8Array)) {
        var r = new Uint8Array(t);
        return m(r.buffer, r.byteOffset, r.byteLength);
      }
      return s(t);
    }
    function m(t, r, e) {
      if (r < 0 || t.byteLength < r)
        throw new RangeError('"offset" is outside of buffer bounds');
      if (t.byteLength < r + (e || 0))
        throw new RangeError('"length" is outside of buffer bounds');
      var i;
      return r === void 0 && e === void 0 ? i = new Uint8Array(t) : e === void 0 ? i = new Uint8Array(t, r) : i = new Uint8Array(t, r, e), Object.setPrototypeOf(i, o.prototype), i;
    }
    function F(t) {
      if (o.isBuffer(t)) {
        var r = _(t.length) | 0, e = l(r);
        return e.length === 0 || t.copy(e, 0, 0, r), e;
      }
      if (t.length !== void 0)
        return typeof t.length != "number" || $(t.length) ? l(0) : s(t);
      if (t.type === "Buffer" && Array.isArray(t.data))
        return s(t.data);
    }
    function _(t) {
      if (t >= g)
        throw new RangeError("Attempt to allocate Buffer larger than maximum size: 0x" + g.toString(16) + " bytes");
      return t | 0;
    }
    function A(t) {
      return +t != t && (t = 0), o.alloc(+t);
    }
    o.isBuffer = function(r) {
      return r != null && r._isBuffer === !0 && r !== o.prototype;
    }, o.compare = function(r, e) {
      if (k(r, Uint8Array) && (r = o.from(r, r.offset, r.byteLength)), k(e, Uint8Array) && (e = o.from(e, e.offset, e.byteLength)), !o.isBuffer(r) || !o.isBuffer(e))
        throw new TypeError(
          'The "buf1", "buf2" arguments must be one of type Buffer or Uint8Array'
        );
      if (r === e)
        return 0;
      for (var i = r.length, n = e.length, u = 0, a = Math.min(i, n); u < a; ++u)
        if (r[u] !== e[u]) {
          i = r[u], n = e[u];
          break;
        }
      return i < n ? -1 : n < i ? 1 : 0;
    }, o.isEncoding = function(r) {
      switch (String(r).toLowerCase()) {
        case "hex":
        case "utf8":
        case "utf-8":
        case "ascii":
        case "latin1":
        case "binary":
        case "base64":
        case "ucs2":
        case "ucs-2":
        case "utf16le":
        case "utf-16le":
          return !0;
        default:
          return !1;
      }
    }, o.concat = function(r, e) {
      if (!Array.isArray(r))
        throw new TypeError('"list" argument must be an Array of Buffers');
      if (r.length === 0)
        return o.alloc(0);
      var i;
      if (e === void 0)
        for (e = 0, i = 0; i < r.length; ++i)
          e += r[i].length;
      var n = o.allocUnsafe(e), u = 0;
      for (i = 0; i < r.length; ++i) {
        var a = r[i];
        if (k(a, Uint8Array))
          u + a.length > n.length ? o.from(a).copy(n, u) : Uint8Array.prototype.set.call(
            n,
            a,
            u
          );
        else if (o.isBuffer(a))
          a.copy(n, u);
        else
          throw new TypeError('"list" argument must be an Array of Buffers');
        u += a.length;
      }
      return n;
    };
    function R(t, r) {
      if (o.isBuffer(t))
        return t.length;
      if (ArrayBuffer.isView(t) || k(t, ArrayBuffer))
        return t.byteLength;
      if (typeof t != "string")
        throw new TypeError(
          'The "string" argument must be one of type string, Buffer, or ArrayBuffer. Received type ' + typeof t
        );
      var e = t.length, i = arguments.length > 2 && arguments[2] === !0;
      if (!i && e === 0)
        return 0;
      for (var n = !1; ; )
        switch (r) {
          case "ascii":
          case "latin1":
          case "binary":
            return e;
          case "utf8":
          case "utf-8":
            return Y(t).length;
          case "ucs2":
          case "ucs-2":
          case "utf16le":
          case "utf-16le":
            return e * 2;
          case "hex":
            return e >>> 1;
          case "base64":
            return ir(t).length;
          default:
            if (n)
              return i ? -1 : Y(t).length;
            r = ("" + r).toLowerCase(), n = !0;
        }
    }
    o.byteLength = R;
    function D(t, r, e) {
      var i = !1;
      if ((r === void 0 || r < 0) && (r = 0), r > this.length || ((e === void 0 || e > this.length) && (e = this.length), e <= 0) || (e >>>= 0, r >>>= 0, e <= r))
        return "";
      for (t || (t = "utf8"); ; )
        switch (t) {
          case "hex":
            return vr(this, r, e);
          case "utf8":
          case "utf-8":
            return Q(this, r, e);
          case "ascii":
            return Fr(this, r, e);
          case "latin1":
          case "binary":
            return Br(this, r, e);
          case "base64":
            return gr(this, r, e);
          case "ucs2":
          case "ucs-2":
          case "utf16le":
          case "utf-16le":
            return Tr(this, r, e);
          default:
            if (i)
              throw new TypeError("Unknown encoding: " + t);
            t = (t + "").toLowerCase(), i = !0;
        }
    }
    o.prototype._isBuffer = !0;
    function I(t, r, e) {
      var i = t[r];
      t[r] = t[e], t[e] = i;
    }
    o.prototype.swap16 = function() {
      var r = this.length;
      if (r % 2 !== 0)
        throw new RangeError("Buffer size must be a multiple of 16-bits");
      for (var e = 0; e < r; e += 2)
        I(this, e, e + 1);
      return this;
    }, o.prototype.swap32 = function() {
      var r = this.length;
      if (r % 4 !== 0)
        throw new RangeError("Buffer size must be a multiple of 32-bits");
      for (var e = 0; e < r; e += 4)
        I(this, e, e + 3), I(this, e + 1, e + 2);
      return this;
    }, o.prototype.swap64 = function() {
      var r = this.length;
      if (r % 8 !== 0)
        throw new RangeError("Buffer size must be a multiple of 64-bits");
      for (var e = 0; e < r; e += 8)
        I(this, e, e + 7), I(this, e + 1, e + 6), I(this, e + 2, e + 5), I(this, e + 3, e + 4);
      return this;
    }, o.prototype.toString = function() {
      var r = this.length;
      return r === 0 ? "" : arguments.length === 0 ? Q(this, 0, r) : D.apply(this, arguments);
    }, o.prototype.toLocaleString = o.prototype.toString, o.prototype.equals = function(r) {
      if (!o.isBuffer(r))
        throw new TypeError("Argument must be a Buffer");
      return this === r ? !0 : o.compare(this, r) === 0;
    }, o.prototype.inspect = function() {
      var r = "", e = x.INSPECT_MAX_BYTES;
      return r = this.toString("hex", 0, e).replace(/(.{2})/g, "$1 ").trim(), this.length > e && (r += " ... "), "<Buffer " + r + ">";
    }, E && (o.prototype[E] = o.prototype.inspect), o.prototype.compare = function(r, e, i, n, u) {
      if (k(r, Uint8Array) && (r = o.from(r, r.offset, r.byteLength)), !o.isBuffer(r))
        throw new TypeError(
          'The "target" argument must be one of type Buffer or Uint8Array. Received type ' + typeof r
        );
      if (e === void 0 && (e = 0), i === void 0 && (i = r ? r.length : 0), n === void 0 && (n = 0), u === void 0 && (u = this.length), e < 0 || i > r.length || n < 0 || u > this.length)
        throw new RangeError("out of range index");
      if (n >= u && e >= i)
        return 0;
      if (n >= u)
        return -1;
      if (e >= i)
        return 1;
      if (e >>>= 0, i >>>= 0, n >>>= 0, u >>>= 0, this === r)
        return 0;
      for (var a = u - n, d = i - e, v = Math.min(a, d), U = this.slice(n, u), q = r.slice(e, i), S = 0; S < v; ++S)
        if (U[S] !== q[S]) {
          a = U[S], d = q[S];
          break;
        }
      return a < d ? -1 : d < a ? 1 : 0;
    };
    function G(t, r, e, i, n) {
      if (t.length === 0)
        return -1;
      if (typeof e == "string" ? (i = e, e = 0) : e > 2147483647 ? e = 2147483647 : e < -2147483648 && (e = -2147483648), e = +e, $(e) && (e = n ? 0 : t.length - 1), e < 0 && (e = t.length + e), e >= t.length) {
        if (n)
          return -1;
        e = t.length - 1;
      } else if (e < 0)
        if (n)
          e = 0;
        else
          return -1;
      if (typeof r == "string" && (r = o.from(r, i)), o.isBuffer(r))
        return r.length === 0 ? -1 : Z(t, r, e, i, n);
      if (typeof r == "number")
        return r = r & 255, typeof Uint8Array.prototype.indexOf == "function" ? n ? Uint8Array.prototype.indexOf.call(t, r, e) : Uint8Array.prototype.lastIndexOf.call(t, r, e) : Z(t, [r], e, i, n);
      throw new TypeError("val must be string, number or Buffer");
    }
    function Z(t, r, e, i, n) {
      var u = 1, a = t.length, d = r.length;
      if (i !== void 0 && (i = String(i).toLowerCase(), i === "ucs2" || i === "ucs-2" || i === "utf16le" || i === "utf-16le")) {
        if (t.length < 2 || r.length < 2)
          return -1;
        u = 2, a /= 2, d /= 2, e /= 2;
      }
      function v(nr, or) {
        return u === 1 ? nr[or] : nr.readUInt16BE(or * u);
      }
      var U;
      if (n) {
        var q = -1;
        for (U = e; U < a; U++)
          if (v(t, U) === v(r, q === -1 ? 0 : U - q)) {
            if (q === -1 && (q = U), U - q + 1 === d)
              return q * u;
          } else
            q !== -1 && (U -= U - q), q = -1;
      } else
        for (e + d > a && (e = a - d), U = e; U >= 0; U--) {
          for (var S = !0, W = 0; W < d; W++)
            if (v(t, U + W) !== v(r, W)) {
              S = !1;
              break;
            }
          if (S)
            return U;
        }
      return -1;
    }
    o.prototype.includes = function(r, e, i) {
      return this.indexOf(r, e, i) !== -1;
    }, o.prototype.indexOf = function(r, e, i) {
      return G(this, r, e, i, !0);
    }, o.prototype.lastIndexOf = function(r, e, i) {
      return G(this, r, e, i, !1);
    };
    function wr(t, r, e, i) {
      e = Number(e) || 0;
      var n = t.length - e;
      i ? (i = Number(i), i > n && (i = n)) : i = n;
      var u = r.length;
      i > u / 2 && (i = u / 2);
      for (var a = 0; a < i; ++a) {
        var d = parseInt(r.substr(a * 2, 2), 16);
        if ($(d))
          return a;
        t[e + a] = d;
      }
      return a;
    }
    function yr(t, r, e, i) {
      return z(Y(r, t.length - e), t, e, i);
    }
    function mr(t, r, e, i) {
      return z(Ur(r), t, e, i);
    }
    function xr(t, r, e, i) {
      return z(ir(r), t, e, i);
    }
    function Er(t, r, e, i) {
      return z(Ir(r, t.length - e), t, e, i);
    }
    o.prototype.write = function(r, e, i, n) {
      if (e === void 0)
        n = "utf8", i = this.length, e = 0;
      else if (i === void 0 && typeof e == "string")
        n = e, i = this.length, e = 0;
      else if (isFinite(e))
        e = e >>> 0, isFinite(i) ? (i = i >>> 0, n === void 0 && (n = "utf8")) : (n = i, i = void 0);
      else
        throw new Error(
          "Buffer.write(string, encoding, offset[, length]) is no longer supported"
        );
      var u = this.length - e;
      if ((i === void 0 || i > u) && (i = u), r.length > 0 && (i < 0 || e < 0) || e > this.length)
        throw new RangeError("Attempt to write outside buffer bounds");
      n || (n = "utf8");
      for (var a = !1; ; )
        switch (n) {
          case "hex":
            return wr(this, r, e, i);
          case "utf8":
          case "utf-8":
            return yr(this, r, e, i);
          case "ascii":
          case "latin1":
          case "binary":
            return mr(this, r, e, i);
          case "base64":
            return xr(this, r, e, i);
          case "ucs2":
          case "ucs-2":
          case "utf16le":
          case "utf-16le":
            return Er(this, r, e, i);
          default:
            if (a)
              throw new TypeError("Unknown encoding: " + n);
            n = ("" + n).toLowerCase(), a = !0;
        }
    }, o.prototype.toJSON = function() {
      return {
        type: "Buffer",
        data: Array.prototype.slice.call(this._arr || this, 0)
      };
    };
    function gr(t, r, e) {
      return r === 0 && e === t.length ? p.fromByteArray(t) : p.fromByteArray(t.slice(r, e));
    }
    function Q(t, r, e) {
      e = Math.min(t.length, e);
      for (var i = [], n = r; n < e; ) {
        var u = t[n], a = null, d = u > 239 ? 4 : u > 223 ? 3 : u > 191 ? 2 : 1;
        if (n + d <= e) {
          var v, U, q, S;
          switch (d) {
            case 1:
              u < 128 && (a = u);
              break;
            case 2:
              v = t[n + 1], (v & 192) === 128 && (S = (u & 31) << 6 | v & 63, S > 127 && (a = S));
              break;
            case 3:
              v = t[n + 1], U = t[n + 2], (v & 192) === 128 && (U & 192) === 128 && (S = (u & 15) << 12 | (v & 63) << 6 | U & 63, S > 2047 && (S < 55296 || S > 57343) && (a = S));
              break;
            case 4:
              v = t[n + 1], U = t[n + 2], q = t[n + 3], (v & 192) === 128 && (U & 192) === 128 && (q & 192) === 128 && (S = (u & 15) << 18 | (v & 63) << 12 | (U & 63) << 6 | q & 63, S > 65535 && S < 1114112 && (a = S));
          }
        }
        a === null ? (a = 65533, d = 1) : a > 65535 && (a -= 65536, i.push(a >>> 10 & 1023 | 55296), a = 56320 | a & 1023), i.push(a), n += d;
      }
      return dr(i);
    }
    var j = 4096;
    function dr(t) {
      var r = t.length;
      if (r <= j)
        return String.fromCharCode.apply(String, t);
      for (var e = "", i = 0; i < r; )
        e += String.fromCharCode.apply(
          String,
          t.slice(i, i += j)
        );
      return e;
    }
    function Fr(t, r, e) {
      var i = "";
      e = Math.min(t.length, e);
      for (var n = r; n < e; ++n)
        i += String.fromCharCode(t[n] & 127);
      return i;
    }
    function Br(t, r, e) {
      var i = "";
      e = Math.min(t.length, e);
      for (var n = r; n < e; ++n)
        i += String.fromCharCode(t[n]);
      return i;
    }
    function vr(t, r, e) {
      var i = t.length;
      (!r || r < 0) && (r = 0), (!e || e < 0 || e > i) && (e = i);
      for (var n = "", u = r; u < e; ++u)
        n += Sr[t[u]];
      return n;
    }
    function Tr(t, r, e) {
      for (var i = t.slice(r, e), n = "", u = 0; u < i.length - 1; u += 2)
        n += String.fromCharCode(i[u] + i[u + 1] * 256);
      return n;
    }
    o.prototype.slice = function(r, e) {
      var i = this.length;
      r = ~~r, e = e === void 0 ? i : ~~e, r < 0 ? (r += i, r < 0 && (r = 0)) : r > i && (r = i), e < 0 ? (e += i, e < 0 && (e = 0)) : e > i && (e = i), e < r && (e = r);
      var n = this.subarray(r, e);
      return Object.setPrototypeOf(n, o.prototype), n;
    };
    function L(t, r, e) {
      if (t % 1 !== 0 || t < 0)
        throw new RangeError("offset is not uint");
      if (t + r > e)
        throw new RangeError("Trying to access beyond buffer length");
    }
    o.prototype.readUintLE = o.prototype.readUIntLE = function(r, e, i) {
      r = r >>> 0, e = e >>> 0, i || L(r, e, this.length);
      for (var n = this[r], u = 1, a = 0; ++a < e && (u *= 256); )
        n += this[r + a] * u;
      return n;
    }, o.prototype.readUintBE = o.prototype.readUIntBE = function(r, e, i) {
      r = r >>> 0, e = e >>> 0, i || L(r, e, this.length);
      for (var n = this[r + --e], u = 1; e > 0 && (u *= 256); )
        n += this[r + --e] * u;
      return n;
    }, o.prototype.readUint8 = o.prototype.readUInt8 = function(r, e) {
      return r = r >>> 0, e || L(r, 1, this.length), this[r];
    }, o.prototype.readUint16LE = o.prototype.readUInt16LE = function(r, e) {
      return r = r >>> 0, e || L(r, 2, this.length), this[r] | this[r + 1] << 8;
    }, o.prototype.readUint16BE = o.prototype.readUInt16BE = function(r, e) {
      return r = r >>> 0, e || L(r, 2, this.length), this[r] << 8 | this[r + 1];
    }, o.prototype.readUint32LE = o.prototype.readUInt32LE = function(r, e) {
      return r = r >>> 0, e || L(r, 4, this.length), (this[r] | this[r + 1] << 8 | this[r + 2] << 16) + this[r + 3] * 16777216;
    }, o.prototype.readUint32BE = o.prototype.readUInt32BE = function(r, e) {
      return r = r >>> 0, e || L(r, 4, this.length), this[r] * 16777216 + (this[r + 1] << 16 | this[r + 2] << 8 | this[r + 3]);
    }, o.prototype.readIntLE = function(r, e, i) {
      r = r >>> 0, e = e >>> 0, i || L(r, e, this.length);
      for (var n = this[r], u = 1, a = 0; ++a < e && (u *= 256); )
        n += this[r + a] * u;
      return u *= 128, n >= u && (n -= Math.pow(2, 8 * e)), n;
    }, o.prototype.readIntBE = function(r, e, i) {
      r = r >>> 0, e = e >>> 0, i || L(r, e, this.length);
      for (var n = e, u = 1, a = this[r + --n]; n > 0 && (u *= 256); )
        a += this[r + --n] * u;
      return u *= 128, a >= u && (a -= Math.pow(2, 8 * e)), a;
    }, o.prototype.readInt8 = function(r, e) {
      return r = r >>> 0, e || L(r, 1, this.length), this[r] & 128 ? (255 - this[r] + 1) * -1 : this[r];
    }, o.prototype.readInt16LE = function(r, e) {
      r = r >>> 0, e || L(r, 2, this.length);
      var i = this[r] | this[r + 1] << 8;
      return i & 32768 ? i | 4294901760 : i;
    }, o.prototype.readInt16BE = function(r, e) {
      r = r >>> 0, e || L(r, 2, this.length);
      var i = this[r + 1] | this[r] << 8;
      return i & 32768 ? i | 4294901760 : i;
    }, o.prototype.readInt32LE = function(r, e) {
      return r = r >>> 0, e || L(r, 4, this.length), this[r] | this[r + 1] << 8 | this[r + 2] << 16 | this[r + 3] << 24;
    }, o.prototype.readInt32BE = function(r, e) {
      return r = r >>> 0, e || L(r, 4, this.length), this[r] << 24 | this[r + 1] << 16 | this[r + 2] << 8 | this[r + 3];
    }, o.prototype.readFloatLE = function(r, e) {
      return r = r >>> 0, e || L(r, 4, this.length), w.read(this, r, !0, 23, 4);
    }, o.prototype.readFloatBE = function(r, e) {
      return r = r >>> 0, e || L(r, 4, this.length), w.read(this, r, !1, 23, 4);
    }, o.prototype.readDoubleLE = function(r, e) {
      return r = r >>> 0, e || L(r, 8, this.length), w.read(this, r, !0, 52, 8);
    }, o.prototype.readDoubleBE = function(r, e) {
      return r = r >>> 0, e || L(r, 8, this.length), w.read(this, r, !1, 52, 8);
    };
    function M(t, r, e, i, n, u) {
      if (!o.isBuffer(t))
        throw new TypeError('"buffer" argument must be a Buffer instance');
      if (r > n || r < u)
        throw new RangeError('"value" argument is out of bounds');
      if (e + i > t.length)
        throw new RangeError("Index out of range");
    }
    o.prototype.writeUintLE = o.prototype.writeUIntLE = function(r, e, i, n) {
      if (r = +r, e = e >>> 0, i = i >>> 0, !n) {
        var u = Math.pow(2, 8 * i) - 1;
        M(this, r, e, i, u, 0);
      }
      var a = 1, d = 0;
      for (this[e] = r & 255; ++d < i && (a *= 256); )
        this[e + d] = r / a & 255;
      return e + i;
    }, o.prototype.writeUintBE = o.prototype.writeUIntBE = function(r, e, i, n) {
      if (r = +r, e = e >>> 0, i = i >>> 0, !n) {
        var u = Math.pow(2, 8 * i) - 1;
        M(this, r, e, i, u, 0);
      }
      var a = i - 1, d = 1;
      for (this[e + a] = r & 255; --a >= 0 && (d *= 256); )
        this[e + a] = r / d & 255;
      return e + i;
    }, o.prototype.writeUint8 = o.prototype.writeUInt8 = function(r, e, i) {
      return r = +r, e = e >>> 0, i || M(this, r, e, 1, 255, 0), this[e] = r & 255, e + 1;
    }, o.prototype.writeUint16LE = o.prototype.writeUInt16LE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || M(this, r, e, 2, 65535, 0), this[e] = r & 255, this[e + 1] = r >>> 8, e + 2;
    }, o.prototype.writeUint16BE = o.prototype.writeUInt16BE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || M(this, r, e, 2, 65535, 0), this[e] = r >>> 8, this[e + 1] = r & 255, e + 2;
    }, o.prototype.writeUint32LE = o.prototype.writeUInt32LE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || M(this, r, e, 4, 4294967295, 0), this[e + 3] = r >>> 24, this[e + 2] = r >>> 16, this[e + 1] = r >>> 8, this[e] = r & 255, e + 4;
    }, o.prototype.writeUint32BE = o.prototype.writeUInt32BE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || M(this, r, e, 4, 4294967295, 0), this[e] = r >>> 24, this[e + 1] = r >>> 16, this[e + 2] = r >>> 8, this[e + 3] = r & 255, e + 4;
    }, o.prototype.writeIntLE = function(r, e, i, n) {
      if (r = +r, e = e >>> 0, !n) {
        var u = Math.pow(2, 8 * i - 1);
        M(this, r, e, i, u - 1, -u);
      }
      var a = 0, d = 1, v = 0;
      for (this[e] = r & 255; ++a < i && (d *= 256); )
        r < 0 && v === 0 && this[e + a - 1] !== 0 && (v = 1), this[e + a] = (r / d >> 0) - v & 255;
      return e + i;
    }, o.prototype.writeIntBE = function(r, e, i, n) {
      if (r = +r, e = e >>> 0, !n) {
        var u = Math.pow(2, 8 * i - 1);
        M(this, r, e, i, u - 1, -u);
      }
      var a = i - 1, d = 1, v = 0;
      for (this[e + a] = r & 255; --a >= 0 && (d *= 256); )
        r < 0 && v === 0 && this[e + a + 1] !== 0 && (v = 1), this[e + a] = (r / d >> 0) - v & 255;
      return e + i;
    }, o.prototype.writeInt8 = function(r, e, i) {
      return r = +r, e = e >>> 0, i || M(this, r, e, 1, 127, -128), r < 0 && (r = 255 + r + 1), this[e] = r & 255, e + 1;
    }, o.prototype.writeInt16LE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || M(this, r, e, 2, 32767, -32768), this[e] = r & 255, this[e + 1] = r >>> 8, e + 2;
    }, o.prototype.writeInt16BE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || M(this, r, e, 2, 32767, -32768), this[e] = r >>> 8, this[e + 1] = r & 255, e + 2;
    }, o.prototype.writeInt32LE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || M(this, r, e, 4, 2147483647, -2147483648), this[e] = r & 255, this[e + 1] = r >>> 8, this[e + 2] = r >>> 16, this[e + 3] = r >>> 24, e + 4;
    }, o.prototype.writeInt32BE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || M(this, r, e, 4, 2147483647, -2147483648), r < 0 && (r = 4294967295 + r + 1), this[e] = r >>> 24, this[e + 1] = r >>> 16, this[e + 2] = r >>> 8, this[e + 3] = r & 255, e + 4;
    };
    function rr(t, r, e, i, n, u) {
      if (e + i > t.length)
        throw new RangeError("Index out of range");
      if (e < 0)
        throw new RangeError("Index out of range");
    }
    function er(t, r, e, i, n) {
      return r = +r, e = e >>> 0, n || rr(t, r, e, 4), w.write(t, r, e, i, 23, 4), e + 4;
    }
    o.prototype.writeFloatLE = function(r, e, i) {
      return er(this, r, e, !0, i);
    }, o.prototype.writeFloatBE = function(r, e, i) {
      return er(this, r, e, !1, i);
    };
    function tr(t, r, e, i, n) {
      return r = +r, e = e >>> 0, n || rr(t, r, e, 8), w.write(t, r, e, i, 52, 8), e + 8;
    }
    o.prototype.writeDoubleLE = function(r, e, i) {
      return tr(this, r, e, !0, i);
    }, o.prototype.writeDoubleBE = function(r, e, i) {
      return tr(this, r, e, !1, i);
    }, o.prototype.copy = function(r, e, i, n) {
      if (!o.isBuffer(r))
        throw new TypeError("argument should be a Buffer");
      if (i || (i = 0), !n && n !== 0 && (n = this.length), e >= r.length && (e = r.length), e || (e = 0), n > 0 && n < i && (n = i), n === i || r.length === 0 || this.length === 0)
        return 0;
      if (e < 0)
        throw new RangeError("targetStart out of bounds");
      if (i < 0 || i >= this.length)
        throw new RangeError("Index out of range");
      if (n < 0)
        throw new RangeError("sourceEnd out of bounds");
      n > this.length && (n = this.length), r.length - e < n - i && (n = r.length - e + i);
      var u = n - i;
      return this === r && typeof Uint8Array.prototype.copyWithin == "function" ? this.copyWithin(e, i, n) : Uint8Array.prototype.set.call(
        r,
        this.subarray(i, n),
        e
      ), u;
    }, o.prototype.fill = function(r, e, i, n) {
      if (typeof r == "string") {
        if (typeof e == "string" ? (n = e, e = 0, i = this.length) : typeof i == "string" && (n = i, i = this.length), n !== void 0 && typeof n != "string")
          throw new TypeError("encoding must be a string");
        if (typeof n == "string" && !o.isEncoding(n))
          throw new TypeError("Unknown encoding: " + n);
        if (r.length === 1) {
          var u = r.charCodeAt(0);
          (n === "utf8" && u < 128 || n === "latin1") && (r = u);
        }
      } else
        typeof r == "number" ? r = r & 255 : typeof r == "boolean" && (r = Number(r));
      if (e < 0 || this.length < e || this.length < i)
        throw new RangeError("Out of range index");
      if (i <= e)
        return this;
      e = e >>> 0, i = i === void 0 ? this.length : i >>> 0, r || (r = 0);
      var a;
      if (typeof r == "number")
        for (a = e; a < i; ++a)
          this[a] = r;
      else {
        var d = o.isBuffer(r) ? r : o.from(r, n), v = d.length;
        if (v === 0)
          throw new TypeError('The value "' + r + '" is invalid for argument "value"');
        for (a = 0; a < i - e; ++a)
          this[a + e] = d[a % v];
      }
      return this;
    };
    var Ar = /[^+/0-9A-Za-z-_]/g;
    function _r(t) {
      if (t = t.split("=")[0], t = t.trim().replace(Ar, ""), t.length < 2)
        return "";
      for (; t.length % 4 !== 0; )
        t = t + "=";
      return t;
    }
    function Y(t, r) {
      r = r || 1 / 0;
      for (var e, i = t.length, n = null, u = [], a = 0; a < i; ++a) {
        if (e = t.charCodeAt(a), e > 55295 && e < 57344) {
          if (!n) {
            if (e > 56319) {
              (r -= 3) > -1 && u.push(239, 191, 189);
              continue;
            } else if (a + 1 === i) {
              (r -= 3) > -1 && u.push(239, 191, 189);
              continue;
            }
            n = e;
            continue;
          }
          if (e < 56320) {
            (r -= 3) > -1 && u.push(239, 191, 189), n = e;
            continue;
          }
          e = (n - 55296 << 10 | e - 56320) + 65536;
        } else
          n && (r -= 3) > -1 && u.push(239, 191, 189);
        if (n = null, e < 128) {
          if ((r -= 1) < 0)
            break;
          u.push(e);
        } else if (e < 2048) {
          if ((r -= 2) < 0)
            break;
          u.push(
            e >> 6 | 192,
            e & 63 | 128
          );
        } else if (e < 65536) {
          if ((r -= 3) < 0)
            break;
          u.push(
            e >> 12 | 224,
            e >> 6 & 63 | 128,
            e & 63 | 128
          );
        } else if (e < 1114112) {
          if ((r -= 4) < 0)
            break;
          u.push(
            e >> 18 | 240,
            e >> 12 & 63 | 128,
            e >> 6 & 63 | 128,
            e & 63 | 128
          );
        } else
          throw new Error("Invalid code point");
      }
      return u;
    }
    function Ur(t) {
      for (var r = [], e = 0; e < t.length; ++e)
        r.push(t.charCodeAt(e) & 255);
      return r;
    }
    function Ir(t, r) {
      for (var e, i, n, u = [], a = 0; a < t.length && !((r -= 2) < 0); ++a)
        e = t.charCodeAt(a), i = e >> 8, n = e % 256, u.push(n), u.push(i);
      return u;
    }
    function ir(t) {
      return p.toByteArray(_r(t));
    }
    function z(t, r, e, i) {
      for (var n = 0; n < i && !(n + e >= r.length || n >= t.length); ++n)
        r[n + e] = t[n];
      return n;
    }
    function k(t, r) {
      return t instanceof r || t != null && t.constructor != null && t.constructor.name != null && t.constructor.name === r.name;
    }
    function $(t) {
      return t !== t;
    }
    var Sr = function() {
      for (var t = "0123456789abcdef", r = new Array(256), e = 0; e < 16; ++e)
        for (var i = e * 16, n = 0; n < 16; ++n)
          r[i + n] = t[e] + t[n];
      return r;
    }();
  }(X)), X;
}
var P = { exports: {} }, fr;
function Or() {
  return fr || (fr = 1, function(x, p) {
    var w = function() {
      if (typeof self < "u")
        return self;
      if (typeof window < "u")
        return window;
      if (typeof ur < "u")
        return ur;
      throw new Error("unable to locate global object");
    }, E = w();
    x.exports = p = E.fetch, E.fetch && (p.default = E.fetch.bind(E)), p.Headers = E.Headers, p.Request = E.Request, p.Response = E.Response;
  }(P, P.exports)), P.exports;
}
var O = { exports: {} }, pr;
function zr() {
  if (pr)
    return O.exports;
  pr = 1;
  const { AbortController: x, AbortSignal: p } = typeof self < "u" ? self : typeof window < "u" ? window : (
    /* otherwise */
    void 0
  );
  return O.exports = x, O.exports.AbortSignal = p, O.exports.default = x, O.exports;
}
var V, cr;
function Wr() {
  if (cr)
    return V;
  cr = 1, V = function(w, E, g) {
    const B = '{"Events":[', l = "]}", o = new w([B]).size + new w([l]).size;
    class T {
      constructor(h) {
        let f = {
          serverUrl: "http://localhost:5341",
          apiKey: null,
          maxBatchingTime: 2e3,
          eventSizeLimit: 262144,
          batchSizeLimit: 1048576,
          requestTimeout: 3e4,
          maxRetries: 5,
          retryDelay: 5e3,
          onError: (c) => {
            console.error("[seq]", c);
          }
        }, y = h || f;
        var s = y.serverUrl || f.serverUrl;
        s.endsWith("/") || (s += "/"), this._endpoint = s + "api/events/raw", this._apiKey = y.apiKey || f.apiKey, this._maxBatchingTime = y.maxBatchingTime || f.maxBatchingTime, this._eventSizeLimit = y.eventSizeLimit || f.eventSizeLimit, this._batchSizeLimit = y.batchSizeLimit || f.batchSizeLimit, this._requestTimeout = y.requestTimeout || f.requestTimeout, this._onError = y.onError || f.onError, this._maxRetries = y.maxRetries || f.maxRetries, this._retryDelay = y.retryDelay || f.retryDelay, this._queue = [], this._timer = null, this._closed = !1, this._activeShipper = null;
      }
      /**
       * Flush events queued at the time of the call, and wait for pending writes to complete regardless of configured batching/timers.
       * @returns {Promise<boolean>}
       */
      flush() {
        return this._ship();
      }
      /**
       * Flush then destroy connections, close the logger, destroying timers and other resources.
       * @returns {Promise<void>}
       */
      close() {
        if (this._closed)
          throw new Error("The logger has already been closed.");
        return this._closed = !0, this._clearTimer(), this.flush();
      }
      /**
       * Enqueue an event in Seq format.
       * @param {*} event
       * @returns {void}
       */
      emit(h) {
        if (!h)
          throw new Error("An event must be provided");
        if (this._closed)
          return;
        let f = this._toWireFormat(h);
        this._queue.push(f), this._activeShipper || this._setTimer();
      }
      _setTimer() {
        this._timer === null && (this._timer = setTimeout(() => {
          this._timer = null, this._onTimer();
        }, this._maxBatchingTime));
      }
      _clearTimer() {
        this._timer !== null && (clearTimeout(this._timer), this._timer = null);
      }
      _onTimer() {
        this._activeShipper || this._ship();
      }
      _toWireFormat(h) {
        var F, _;
        const f = typeof h.level == "string" ? h.level : void 0, y = h.timestamp instanceof Date ? h.timestamp : /* @__PURE__ */ new Date(), s = typeof h.messageTemplate == "string" ? h.messageTemplate : h.messageTemplate !== null && h.messageTemplate !== void 0 ? h.messageTemplate.toString() : "(No message provided)", c = typeof h.exception == "string" ? h.exception : h.exception !== null && h.exception !== void 0 ? h.exception.toString() : void 0, m = typeof h.properties == "object" ? h.properties : void 0;
        return {
          Timestamp: y,
          Level: f,
          TraceId: (F = h.traceId) == null ? void 0 : F.toString(),
          SpanId: (_ = h.spanId) == null ? void 0 : _.toString(),
          MessageTemplate: s,
          Exception: c,
          Properties: m
        };
      }
      _eventTooLargeErrorEvent(h) {
        return {
          Timestamp: h.Timestamp,
          Level: h.Level,
          MessageTemplate: "(Event too large) {initial}...",
          Properties: {
            initial: h.MessageTemplate.substring(0, 12),
            sourceContext: "Seq Javascript Client",
            eventSizeLimit: this._eventSizeLimit
          }
        };
      }
      _reset(h) {
        this._activeShipper === h && (this._activeShipper = null, this._queue.length !== 0 && this._setTimer());
      }
      /**
       *
       * @returns {Promise<boolean>}
       */
      _ship() {
        if (this._queue.length === 0)
          return Promise.resolve(!1);
        let h = this._activeShipper || Promise.resolve(!1), f = this._activeShipper = h.then(() => {
          let y = (s) => s ? !0 : this._sendBatch().then((c) => y(c));
          return this._sendBatch().then((s) => y(s));
        }).then(() => this._reset(f), (y) => {
          this._onError(y), this._reset(f);
        });
        return f;
      }
      _sendBatch() {
        if (this._queue.length === 0)
          return Promise.resolve(!0);
        let h = this._dequeBatch(), f = this._queue.length === 0;
        return this._post(h.batch, h.bytes).then(() => f);
      }
      _dequeBatch() {
        var h = o;
        let f = [];
        for (var y = 0, s = 0; y < this._queue.length; ) {
          let m = this._queue[y], F;
          try {
            F = JSON.stringify(m);
          } catch {
            const A = p(m);
            F = JSON.stringify(A), this.emit({
              timestamp: A.Timestamp,
              level: "Error",
              messageTemplate: "[seq] Circular structure found"
            });
          }
          var c = new w([F]).size;
          if (c > this._eventSizeLimit && (this._onError("[seq] Event body is larger than " + this._eventSizeLimit + " bytes: " + F), this._queue[y] = m = this._eventTooLargeErrorEvent(m), F = JSON.stringify(m), c = new w([F]).size), y !== 0 && h + c + s > this._batchSizeLimit)
            break;
          y = y + 1, h += c + s, s = 1, f.push(F);
        }
        return this._queue.splice(0, y), { batch: f, bytes: h };
      }
      _httpOrNetworkError(h) {
        return ["ECONNRESET", "ENOTFOUND", "ESOCKETTIMEDOUT", "ETIMEDOUT", "ECONNREFUSED", "EHOSTUNREACH", "EPIPE", "EAI_AGAIN", "EBUSY"].includes(h) || 500 <= h.status && h.status < 600;
      }
      _post(h, f) {
        let y = 0;
        return new Promise((s, c) => {
          const m = (F, _) => {
            const A = new g();
            y++;
            const R = setTimeout(() => {
              A.abort(), y > this._maxRetries ? c("HTTP log shipping failed, reached timeout (" + this._requestTimeout + " ms)") : setTimeout(() => m(F, _), this._retryDelay);
            }, this._requestTimeout);
            E(this._endpoint, {
              keepalive: !0,
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "X-Seq-ApiKey": this._apiKey ? this._apiKey : null,
                "Content-Length": _
              },
              body: `${B}${F.join(",")}${l}`,
              signal: A.signal
            }).then((D) => {
              clearTimeout(R);
              let I = null;
              return D.status !== 200 && D.status !== 201 ? (I = "HTTP log shipping failed: " + D.status, this._httpOrNetworkError(D) && y < this._maxRetries ? setTimeout(() => m(F, _), this._retryDelay) : c(I)) : s(!0);
            }).catch((D) => {
              clearTimeout(R), c(D);
            });
          };
          return m(h, f);
        });
      }
    }
    return T;
  };
  const x = (w) => !w || typeof w != "object", p = (w, E = /* @__PURE__ */ new Map(), g = "root") => {
    if (x(w))
      return w;
    if (E.has(w))
      return "== Circular structure: '" + E.get(w).replace("root.Properties.", "") + "' ==";
    if (E.set(w, g), w instanceof Array)
      return w.map(
        (o, T) => x(o) ? o : p(o, new Map(E), g + `[${T}]`)
      );
    const B = Object.keys(w);
    if (B.length === 0)
      return w;
    const l = {};
    return B.forEach((o) => {
      const T = w[o];
      if (x(T)) {
        l[o] = T;
        return;
      }
      l[o] = p(T, new Map(E), g + "." + o);
    }), l;
  };
  return V;
}
let Hr = Wr()(
  typeof Blob < "u" ? Blob : br().Blob,
  typeof fetch < "u" ? fetch : Or(),
  typeof AbortController < "u" ? AbortController : zr()
);
var Pr = { Logger: Hr };
const Jr = /* @__PURE__ */ Rr(Pr), N = console.error, J = console.warn, K = console.info, lr = Lr({
  // eslint-disable-next-line complexity
  log: (x, p, w) => {
    const E = w ? Object.keys(w).reduce((g, B) => g.replace(new RegExp(`{${B}}`, "g"), JSON.stringify(w[B]))) : p;
    switch (x) {
      case "Verbose":
      case "Debug":
      case "Information":
        K(E);
        break;
      case "Warning":
        J(E);
        break;
      case "Error":
      case "Fatal":
        N(E);
        break;
    }
  }
}), Xr = (x) => {
  const {
    serverUrl: p = "https://seq.oncoreflex.dev",
    apiKey: w,
    tags: E,
    redirectError: g = !1,
    redirectWarn: B = !1,
    redirectInfo: l = !1,
    children: o
  } = x, T = qr(() => new Jr.Logger({
    serverUrl: p,
    apiKey: w,
    onError: (h) => N(h)
  }), [p, w]);
  Dr(() => () => {
    T.flush().then();
  });
  const C = (h, f, y) => {
    try {
      T.emit({
        level: h,
        timestamp: /* @__PURE__ */ new Date(),
        messageTemplate: f,
        properties: {
          ...E,
          ...y
        }
      });
    } catch (s) {
      N(s);
    }
  };
  return console.error = g ? function(...h) {
    try {
      h.forEach((f) => C("Error", f));
    } catch (f) {
      N(f);
    }
    N(...h);
  } : N, console.warn = B ? function(...h) {
    try {
      h.forEach((f) => C("Warning", f));
    } catch (f) {
      J(f);
    }
    J(...h);
  } : J, console.info = l ? function(...h) {
    try {
      h.forEach((f) => C("Information", f));
    } catch (f) {
      K(f);
    }
    K(...h);
  } : K, /* @__PURE__ */ Cr.jsx(lr.Provider, { value: {
    log: (h, f, y) => C(h, f, y)
  }, children: o });
}, Vr = () => Mr(lr);
export {
  Xr as LoggingProvider,
  Vr as useLogging
};
