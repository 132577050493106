export const positionFieldsTerminology = {
  title: {
    name: 'Title'
  },
  description: {
    name: 'Description',
    value: {
      name: 'Description'
    },
  },
  positionDescription: {
    name: 'Position description'
  },
  location: {
    name: 'Location'
  },
  startDate: {
    name: 'Start date'
  },
  endDate: {
    name: 'End date'
  },
  rate: {
    name: 'Rate'
  },
  unitsPerWeek: {
    name: 'Units per week',
  },
  unit: {
    name: 'Unit',
  },
  workerFeeType: {
    name: 'Worker fee',
  },
  workerFeeBasis: {
    name: 'Position basis',
  },
  costCentre: {
    name: 'Cost centre',
  },
  project: {
    name: 'Project',
  },
  organisationalUnit: {
    name: 'Organisational unit',
  },
  payrollTax: {
    name: 'State/Level',
  },
  workerFee: {
    name: 'Rate',
    rate: {
      name: 'Rate'
    },
    unit: {
      name: 'Unit'
    },
  },
  workerSchedule: {
    name: 'Schedule',
    unitsPerPeriod: {
      name: 'Units per week'
    },
    type: {
      name: 'Type'
    },
  },
  // TODO
  agencyFees: {
    name: 'Agency markup',
    period: {
      name: 'Period'
    },
    rate: {
      name: 'Agency markup'
    },
    unit: {
      name: 'Unit'
    },
    type: {
      name: 'As percentage'
    }
  },
  oncoreFee: {
    name: 'Oncore fee',
    rate: {
      name: 'Oncore fee'
    },
    unit: {
      name: 'Unit'
    },
    type: {
      name: 'As percentage'
    }
  }
} as const;

export const positionCandidateFieldsTerminology = {
  workerContact: {
    firstName: {
      name: 'First name'
    },
    lastName: {
      name: 'Last name'
    }
  },
  introduction: {
    value: {
      name: 'Introduction'
    },
  },
  workerFee: {
    rate: {
      name: 'Rate'
    },
    unit: {
      name: 'Unit'
    },
  },
  agencyFee: {
    rate: {
      name: 'Rate'
    },
    unit: {
      name: 'Unit'
    },
    type: {
      name: 'As percentage'
    }
  },
  coverLetter: {
    name: 'Cover letter',
  },
  cv: {
    name: 'CV',
  }
} as const;

export const placementFieldsTerminology = {
  supplier: {
    name: 'Supplier',
  },
  workerContact: {
    firstName: {
      name: 'First name'
    },
    middleName: {
      name: 'Middle name'
    },
    lastName: {
      name: 'Last name'
    },
    mobile: {
      name: 'Contact phone'
    },
    email: {
      name: 'Email address'
    },
  },
  notes: {
    value: {
      name: 'Additional notes'
    },
  },
  engagementType: {
    name: 'Engagement type'
  },
  engagementEntity: {
    identityNumber: {
      name: 'Company ABN'
    },
    displayName: {
      name: 'Company name'
    }
  },
  startDate: {
    name: 'Start date'
  },
  endDate: {
    name: 'End date'
  },
  noticePeriod: {
    name: 'Notice period'
  },
  positionTitle: {
    name: 'Position title'
  },
  positionNumber: {
    name: 'Position number'
  },
  purchaseOrderNumber: {
    name: 'Purchase order'
  },
  division: {
    name: 'Division',
  },
  department: {
    name: 'Department',
  },
  costCentre: {
    name: 'Cost centre',
  },
  project: {
    name: 'Project',
  },
  location: {
    name: 'Location',
  },
  lineManager: {
    name: 'Line manager',
  },
  workerRates: {
    value: {
      name: 'Rate'
    },
    unit: {
      name: 'Unit'
    },
    type: {
      name: 'Type',
    },
    notes: {
      name: 'Notes'
    }
  },
  agencyContact: {
    firstName: {
      name: 'First name'
    },
    lastName: {
      name: 'Last name'
    },
    mobile: {
      name: 'Contact number'
    },
    email: {
      name: 'Email'
    },
    identity: {
      name: 'Name',
    }
  },
  agencyFee: {
    rate: {
      name: 'Rate'
    },
    unit: {
      name: 'Unit'
    },
    type: {
      name: 'As percentage'
    }
  },
  oncoreFee: {
    rate: {
      name: 'Rate'
    },
    unit: {
      name: 'Unit'
    },
    type: {
      name: 'As percentage'
    }
  },
  billingContact: {
    firstName: {
      name: 'First name'
    },
    lastName: {
      name: 'Last name'
    },
    mobile: {
      name: 'Contact number'
    },
    email: {
      name: 'Email'
    },
  },
  billingAddress: {
    name: 'Site/Office',
  },
  billingReference: {
    name: 'Billing reference'
  },
  instructionToEngage: {
    confirmed: {
      name: 'Confirmation of instruction to engage'
    },
    confirmedBy: {
      name: 'Authorising officer',
    }
  },
  organisationalUnitID: {
    name: 'Organisational unit'
  }
} as const;

const terminology = {
  profile: {
    messages: {
      updateSuccess: 'Profile has been updated successfully',
      updateFailure: 'Failed to update profile. Please refresh the page and try again.'
    },
    tabs: {
      profile: 'Profile',
      details: 'Details',
      security: 'Security',
      tenants: 'Tenants',
      bankDetails: 'Bank details',
      companyDetails: 'Company details',
      legalNameChange: 'Request name change',
      legalDetailsNameChange: 'Details: Request name change'
    }
  },
  errors: {
    generic: {
      query: 'Failed to retrieve data. Please refresh the page or if the error persists, try again later.',
      download: (fileName?: string) => `Failed to download the file${fileName ? ` (${fileName})` : ''}.`
    }
  },
  messages: {
    titles: {
      actionWarning: 'Action warning',
      error: 'Error',
      cannotProceed: 'Action not possible',
      approveThisPosition: 'Approve this position',
      rejectThisPosition: 'Reject this position',
      approveThisTimesheet: 'Approve this timesheet',
      rejectThisTimesheet: 'Reject this timesheet'
    },
    buttons: {
      ok: 'OK',
      proceed: 'I understand, proceed',
      cancel: 'Cancel',
      create: 'Create',
      update: 'Update',
      invite: 'Invite',
      next: 'Next',
      submit: 'Submit',
      save: 'Save'
    }
  },
  confirmations: {
    quitNew: `The node you were editing was not saved yet, 
      if you continue, your changes will be lost. Click OK to continue.`
  },
  positions: {
    names: {
      sentenceCase: 'Position',
      titleCase: 'Position',
      upperCase: 'POSITION',
      lowerCase: 'position'
    },
    lexicon: {
      searchHint: 'Search a position',
      statusHint: 'Status',
      locationHint: 'Location',
      summaryTitle: 'Positions',
      createTitle: 'New position',
      editTitle: (displayName: string): string => `Position: ${displayName}`
    },
    fields: positionFieldsTerminology,
    contract: {
      header: 'Contract',
      duration: {
        header: 'Duration'
      },
      costAllocation: {
        header: 'Cost allocation'
      },
      division: {
        header: 'Division'
      },
      location: {
        header: 'Location'
      }
    },
    rate: {
      header: 'Rate',
      targetContractorRate: {
        header: 'Target worker rate*'
      }
    },
    budget: {
      header: 'Budget',
      payrollTax: {
        header: 'Payroll tax'
      },
      agencyFees: {
        header: 'Agency fees (daily)*'
      },
      oncoreFees: {
        header: 'Oncore fees (daily)*'
      },
      contractorRateTotals: {
        header: 'Worker rate totals (daily)*'
      },
      contractTotals: {
        header: 'Contract totals*'
      }
    },
    collaborators: {
      header: 'Collaborators',
      placeholder: 'Type to add collaborators',
      searchHint: 'Search a collaborator',
      levels: {
        reader: 'Reviewer',
        writer: 'Editor',
        approver: 'Approver'
      }
    },
    recruiters: {
      header: 'Recruiters',
      placeholder: 'Type to add recruiters',
      searchHint: 'Search a recruiter'
    },
    candidates: {
      header: 'Candidates',
      fields: positionCandidateFieldsTerminology,
      lexicon: {
        createTitle: 'New candidate',
        editTitle: 'Candidate'
      },
      details: {
        header: 'Details'
      }
    },
    comments: {
      header: 'Comments'
    },
    history: {
      header: 'History',
      title: 'Position Activity',
      strings: {
        pre: {
          wasChangedFrom: 'was changed from',
          wasRemoved: 'was removed'
        },
        post: {
          wasSetTo: 'was set to',
          wasChangedTo: 'to'
        }
      }
    },
    global: {
      statuses: {
        candidate: {
          pending: 'Pending',
          interviewing: 'Interviewing',
          succeeded: 'Succeeded',
          rejected: 'Rejected',
          undefined: 'Pending'
        }
      },
      other: {
        editingEnabled: 'Editing enabled',
        invited: 'Invited'
      }
    }
  },
  position: {
    lexicon: {
      position: 'Position'
    },
    details: {
      tabTitle: 'Details',
      header: 'Position details',
      position: {
        header: 'Position'
      }
    },
    history: {
      header: 'Position history',
      tabTitle: 'History'
    }
  },
  timesheetEntry: {
    lexicon: {
      header: 'Timesheet entry',
      noEntries: {
        header: 'It looks like you’re all up to date!',
        text: 'There are no timesheets to complete.'
      }
    },
    timeWorked: {
      title: 'Time worked',
      fields: {
        startTime: {
          label: 'Start time'
        },
        endTime: {
          label: 'Stop time'
        },
        breakStartTime: {
          label: 'Break start'
        },
        breakDuration: {
          label: 'Break duration'
        },
        notes: {
          label: 'Notes'
        },
        total: {
          label: 'Total hours'
        }
      },
      actions: {
        copyDown: 'Copy down',
        addNotes: 'Add notes',
        clear: 'Clear'
      }
    },
    billables: {
      title: 'Timesheet Billables',
      fields: {
        rate: {
          label: 'Rate'
        },
        rateType: {
          label: 'Rate type'
        },
        date: {
          label: 'Date'
        },
        units: {
          label: 'Billable units'
        },
        jobReference: {
          label: 'Job reference'
        },
        description: {
          label: 'Job description'
        },
        notes: {
          label: 'Notes'
        },
        totals: {
          label: 'Totals'
        }
      },
      actions: {
        addNotes: 'Add notes',
        delete: 'Delete',
        addNew: 'Add new'
      }
    },
    totals: {
      title: 'Billable Totals'
    },
    messages: {
      updateSuccess: 'Timesheet entry has been updated successfully.',
      updateFailure: 'Failed to update timesheet entry. Please refresh the page and try again.',
      submitSuccess: 'Timesheet entry has been submited successfully.',
      submitFailure: 'Failed to submit timesheet entry. Please refresh the page and try again.',
    },
  },
  timesheets: {
    global: {
      statuses: {
        submitted: 'Submitted',
        resubmitted: 'Resubmitted',
        approved: 'Approved',
        rejected: 'Rejected'
      },
      other: {
        editingEnabled: 'Editing enabled',
        invited: 'Invited'
      }
    },
    lexicon: {
      timesheets: 'Timesheets',
      timesheet: 'Timesheet',
      searchHint: 'Search a timesheet',
      editTitle: 'Timesheet details',
      editTabTitle: 'Details',
      historyTitle: 'Timesheet Activity',
      historyTabTitle: 'History',
      approvers: 'Approvers'
    }
  },
  placements: {
    header: 'Placements',
    fields: placementFieldsTerminology,
    lexicon: {
      createTitle: 'New placement',
      editTitle: 'Placement'
    },
    supplier: {
      header: 'Supplier',
      label: 'Select a supplier *',
      direct: 'Direct'
    },
    worker: {
      header: 'Worker details'
    },
    company: {
      header: 'Pty Ltd',
      identityNumber: {
        header: 'Company ABN'
      },
      displayName: {
        header: 'Company name'
      }
    },
    contract: {
      header: 'Contract details',
      duration: {
        header: 'Duration',
        contractDuration: 'Contract duration'
      },
      position: {
        header: 'Position'
      },
      costAllocation: {
        header: 'Cost allocation'
      },
      division: {
        header: 'Division'
      },
      location: {
        header: 'Location'
      }
    },
    lineManager: {
      header: 'Line manager',
      searchHint: 'Type to add a line manager'
    },
    rate: {
      header: 'Worker rates',
      excludeGST: {
        header: 'Exclusive of GST'
      }
    },
    timesheetApprovers: {
      header: 'Timesheet approvers',
      searchHint: 'Type to add a timesheet approver'
    },
    billing: {
      header: 'Invoicing and payroll',
      costAllocation: {
        header: 'Cost allocation'
      },
      billingContact: {
        header: 'Billing contact'
      },
      billingAddress: {
        header: 'Billing address'
      },
      purchaseOrderNumber: {
        header: 'Purchase order'
      }
    },
    agency: {
      header: 'Recruitment agency',
      agencyContact: {
        header: 'Agency contact'
      },
      agencyFee: {
        header: 'Agency margin'
      },
      oncoreFee: {
        header: 'Chargeable on-costs'
      }
    },
    screenings: {
      header: 'Screenings'
    },
    documents: {
      header: 'Documents'
    },
    notes: {
      header: 'Additional notes'
    },
    confirmation: {
      header: 'Confirmation of instruction to engage'
    },
    organisationalUnitID: {
      header: 'Organization unit'
    }
  },
  global: {
    lexicon: {
      add: 'Add',
      save: 'Save',
      saveAndAddNew: 'Save + Add New',
      interview: 'Interview',
      success: 'Success',
      update: 'Update',
      remove: 'Remove',
      delete: 'Delete',
      invite: 'Invite',
      download: 'Download',
      sendInvite: 'Send invite',
      addCandidate: 'Add candidate',
      viewDetails: 'View details',
      editUser: 'Edit user',
      editGroup: 'Edit group',
      approve: 'Approve',
      reject: 'Reject',
      cancel: 'Cancel',
      onboard: 'Onboard',
      requestApproval: 'Request approval',
      approvalNotRequired: 'Approval not required',
      enableEditing: 'Enable editing',
      disableEditing: 'Disable editing',
      putOnHold: 'Put on hold',
      readyToHire: 'Ready to hire',
      returnToDraft: 'Return to draft',
      home: 'Home',
      administration: 'Administration',
      moveDown: 'Move down',
      moveUp: 'Move up'
    },
    warnings: {
      missingConfirmationReference:
        'Confirmation component was provided, however object reference is missing. '
        + 'Will proceed without confirmation.'
    },
    messages: {
      noMatchingRecords: 'No match',
      thereArePendingChanges: 'You have unsaved changes, are you sure you want to leave?',
      adminCreateGroupSuccess: 'Group has been created successfully.',
      adminCreateGroupFailure: 'Failed to create group. Please refresh the page and try again.',
      adminCreateOrgUnitSuccess: 'Organisational unit has been created successfully.',
      adminCreateOrgUnitFailure: 'Failed to create organisational unit. Please refresh the page and try again.',
      adminCreateLocationSuccess: 'Location has been created successfully.',
      adminCreateLocationFailure: 'Failed to create location. Please refresh the page and try again.',
      adminDeleteGroupSuccess: 'Group has been deleted successfully.',
      adminDeleteGroupFailure: 'Failed to delete group. Please refresh the page and try again.',
      adminDeleteOrgUnitSuccess: 'Organisational unit has been deleted successfully.',
      adminDeleteOrgUnitFailure: 'Failed to delete organisation unit. Please refresh the page and try again.',
      adminDeleteLocationSuccess: 'Location has been deleted successfully.',
      adminDeleteLocationFailure: 'Failed to delete location. Please refresh the page and try again.',
      adminReferenceScreeningOptInSuccess: 'Screening has been opted-in successfully.',
      adminReferenceScreeningOptInFailure: 'Failed to opt-in the screening. Please refresh the page and try again',
      adminReferenceScreeningOptOutSuccess: 'Screening has been opted-out successfully.',
      adminReferenceScreeningOptOutFailure: 'Failed to opt-out the screening. Please refresh the page and try again',
      adminDeleteUserSuccess: 'User has been deleted successfully.',
      adminDeleteUserFailure: 'Failed to delete user. Please refresh the page and try again.',
      adminInviteUserSuccess: 'User has been invited successfully.',
      adminInviteUserFailure: 'Failed to invite user. Please refresh the page and try again.',
      adminUpdateGroupSuccess: 'Group has been updated successfully.',
      adminUpdateGroupFailure: 'Failed to update group. Please refresh the page and try again.',
      adminUpdateOrgUnitSuccess: 'Organisational unit has been updated successfully.',
      adminUpdateOrgUnitFailure: 'Failed to update organisational unit. Please refresh the page and try again.',
      adminUpdateLocationSuccess: 'Location has been updated successfully.',
      adminUpdateLocationFailure: 'Failed to update location. Please refresh the page and try again.',
      adminUpdateUserSuccess: 'User has been updated successfully.',
      adminUpdateUserFailure: 'Failed to update user. Please refresh the page and try again.',
      adminUpdateOrgUnitsSettingsSuccess: 'Organisational structure settings has been updated successfully.',
      adminUpdateOrgUnitsSettingsFailure: 'Failed to update organisational structure settings. Please refresh the page and try again.',
      adminUpdateLocationsSettingsSuccess: 'Locations settings has been updated successfully.',
      adminUpdateLocationsSettingsFailure: 'Failed to update locations settings. Please refresh the page and try again.',
      approvePositionSuccess: 'Position has been approved successfully.',
      approvePositionFailure: 'Failed to approve position. Please refresh the page and try again.',
      approveTimesheetSuccess: 'Timesheet has been approved successfully.',
      approveTimesheetFailure: 'Failed to approve timesheet. Please refresh the page and try again.',
      createPositionSuccess: 'Position has been created successfully.',
      createPositionFailure: 'Failed to create position. Please refresh the page and try again.',
      updatePositionSuccess: 'Position has been updated successfully.',
      updatePositionFailure: 'Failed to update position. Please refresh the page and try again.',
      transitPositionSuccess: 'Position has been updated successfully.',
      transitPositionFailure: 'Failed to update position. Please refresh the page and try again.',
      rejectPositionSuccess: 'Position has been rejected successfully.',
      rejectPositionFailure: 'Failed to reject position. Please refresh the page and try again.',
      rejectTimesheetSuccess: 'Timesheet has been rejected successfully.',
      rejectTimesheetFailure: 'Failed to reject timesheet. Please refresh the page and try again.',
      createPositionCandidateSuccess: 'Position candidate has been added successfully.',
      createPositionCandidateFailure: 'Failed to add position candidate. Please refresh the page and try again.',
      updatePositionCandidateSuccess: 'Position candidate has been updated successfully.',
      updatePositionCandidateFailure: 'Failed to update position candidate. Please refresh the page and try again.',
      createPositionCommentSuccess: 'Position comment has been added successfully.',
      createPositionCommentFailure: 'Failed to add position comment. Please refresh the page and try again.',
      transitPositionCandidateSuccess: 'Position candidate has been updated successfully.',
      transitPositionCandidateFailure: 'Failed to update position candidate. Please refresh the page and try again.',
      createPlacementSuccess: 'Position placement has been added successfully.',
      createPlacementFailure: 'Failed to add position placement. Please refresh the page and try again.',
      updateContractSuccess: 'Contract has been updated successfully.',
      updateContractFailure: 'Failed to update contract. Please refresh the page and try again.',
      lodgeEmailUpdateFailure: 'Failed to process your change email request. Please refresh the page and try again.',
      lodgeEmailUpdateSuccess: 'Your email request has been received.',
      lodgeLegalNameUpdateFailure: 'Failed to process your change name request. Please refresh the page and try again.',
      lodgeLegalNameUpdateSuccess: 'Your change name request has been received. ' +
        'Please allow 1-2 business days for your name to be updated and reflected in your profile',
      expenseCreationSuccess: 'Expense has been created successfully.',
      expenseCreationFailure: 'Failed to create expense.',
    },
    home: 'Home',
    contracts: 'Contracts',
    contract: 'Contract',
    groups: 'Groups',
    group: 'Group',
    users: 'Users',
    user: 'User'
  },
  admin: {
    groups: {
      tabs: {
        details: 'Details'
      },
      profile: {
        header: 'Profile',
        displayName: 'Display name',
        displayNameExistsFailure: 'This Display name is already taken. Please choose another one.'
      },
      permissions: {
        header: 'Permissions'
      },
      users: {
        header: 'Users',
        searchHint: 'Type to add a user'
      },
      lexicon: {
        searchHint: 'Search a group',
        summaryTitle: 'Administration: groups',
        createTitle: 'New group',
        editTitle: (displayName: string): string => `Group: ${displayName}`
      }
    },
    users: {
      tabs: {
        details: 'Details'
      },
      profile: {
        header: 'Profile',
        email: 'Email',
        mobile: 'Mobile',
        firstName: 'First name',
        lastName: 'Last name',
        displayName: 'Display name'
      },
      permissions: {
        header: 'Permissions'
      },
      groups: {
        header: 'Groups',
        searchHint: 'Type to add a group'
      },
      lexicon: {
        searchHint: 'Search a user',
        summaryTitle: 'Administration: users',
        createTitle: 'Invite user',
        editTitle: (displayName: string): string => `User: ${displayName}`
      }
    },
    locations: {
      tabs: {
        structure: 'Structure',
        settings: 'Settings'
      },
      breadcrumb: 'Locations',
      lexicon: {
        summaryTitle: 'Administration: locations',
        createTitle: 'New location',
        structureTitle: 'Structure',
        settingsTitle: 'Settings',
        locationAddTitle: 'Add a new location',
        locationAddBreadcrumb: 'New location',
        locationViewTitle: 'Edit a location',
        locationViewBreadcrumb: 'Edit location'
      },
      unit: {
        displayName: 'Title'
      }
    },
    orgunits: {
      tabs: {
        structure: 'Structure',
        settings: 'Settings'
      },
      breadcrumb: 'Organisation',
      lexicon: {
        structureTitle: 'Structure',
        settingsTitle: 'Settings',
        unitAddTitle: 'Add a new level',
        unitAddBreadcrumb: 'New level',
        unitViewTitle: 'Edit a level',
        unitViewBreadcrumb: 'Edit level'
      },
      unit: {
        displayName: 'Title'
      }
    },
    screenings: {
      lexicon: {
        summaryTitle: 'Administration: screenings',
        searchHint: 'Search a screening'
      }
    }
  },
  contracts: {
    tabs: {
      details: 'Details',
      approvers: 'Approvers'
    },
    approvers: {
      timesheetSearchHint: 'Type to add a timesheet approver',
      expenseSearchHint: 'Type to add a expense approver'
    },
    supplier: {
      header: 'Supplier',
      name: 'Supplier',
      label: 'Select a supplier *',
      direct: 'Direct'
    },
    contractsOverview: {
      fullName: {
        header: 'Worker'
      },
      client: {
        header: 'End client'
      },
      sourceOfHire: {
        header: 'Source'
      },
      startDate: {
        header: 'Start'
      },
      endDate: {
        header: 'End'
      },
      contractDuration: {
        header: 'Term'
      },
      workerType: {
        header: 'Type'
      },
      rate: {
        header: 'Rate'
      },
      daysRemaining: {
        header: 'Left'
      }
    },
    lexicon: {
      searchHint: 'Search a contract',
      contracts: 'Contracts',
      editTitle: (displayName: string): string => `Contract: ${displayName}`,
      engagementType: 'Engagement type'
    }
  },
  notifications: {
    title: 'Notifications',
    filterUnread: 'Only show unread',
    markAll: 'Mark all as read',
    thatsAll: 'That’s all your unread messages in the last 30 days.',
    youReadAll: 'You’ve read all your notifications from the last 30 days'
  },
  invoices: {
    lexicon: {
      summaryTitle: 'Invoices'
    }
  },
  expenses: {
    lexicon: {
      summaryTitle: 'Expenses',
      newClaim: 'New Claim',
      summaryEmptyList: {
        header: 'You’re ready to add your first expense',
        text: 'Click the New Claim button above to begin.'
      },
      summaryNoResults: {
        header: 'You have no matching expenses',
        text: 'Update your search to find matching expenses.'
      }
    },
    overview: {
      summary: {
        header: 'Summary'
      },
      employment: {
        header: 'Employment'
      },
      contract: {
        header: 'Contract'
      },
      createdAt: {
        header: 'Date created'
      },
      claimed: {
        header: 'Claimed'
      },
      claimTotal: {
        header: 'Claim total'
      },
      rejected: {
        header: 'Rejected'
      },
      accepted: {
        header: 'Accepted'
      },
      paid: {
        header: 'Paid'
      }
    }
  },
  expense: {
    lexicon: {
      title: 'Claim details',
      clientPaidTitle: 'Client paid claim details',
      packagedTitle: 'Packaged claim details',
      expenses: 'Expenses',
      expenseDocuments: 'Expense supporting documents',
      documents: 'Claim supporting documents',
      notes: 'Claim notes',
      totals: 'Claim totals'
    },
    messages: {
      createSuccess: 'Expense has been created successfully.',
      createFailure: 'Failed to create expense. Please refresh the page and try again.',
      updateSuccess: 'Expense has been updated successfully.',
      updateFailure: 'Failed to update expense. Please refresh the page and try again.',
      submitSuccess: 'Expense has been submited successfully.',
      submitFailure: 'Failed to submit expense. Please refresh the page and try again.',
      submitWithoutExpensesError: 'There are no expenses attached to this claim! Please provide at least one expense before submitting.'
    },
    fields: {
      summary: {
        label: 'Claim summary'
      },
      notes: {
        label: 'Claim notes'
      },
      expense: {
        type: {
          label: 'Expense type'
        },
        date: {
          label: 'Invoice date'
        },
        startDate: {
          label: 'Start date'
        },
        endDate: {
          label: 'End date'
        },
        amount: {
          label: 'Amount'
        },
        days: {
          label: 'Business days'
        },
        percentage: {
          label: 'Work related'
        },
        claimAmount: {
          label: 'Claim amount'
        },
        description: {
          label: 'Description'
        },
        location: {
          label: 'Destination city'
        },
        place: {
          label: 'Place'
        },
        periodEnding: {
          label: 'Period ending'
        },
        kmsToDate: {
          label: 'Km’s to date'
        },
        numberOfKms: {
          label: 'Number of Km’s'
        },
        dta: {
          substantiating: {
            header: 'Substantiating the Nature of the Travel',
            option_true: 'The work performed whilst on travel is being billed to the client',
            option_false: 'No work is being billed. An explanation is provided below'
          },
          associated: {
            header: 'Associated Expenses (flights & accommodation)',
            option_true: 'Associated expenses are also being claimed through the expense system',
            option_false: 'There are no associated expenses being claimed. An explanation is provided below'
          }
        }
      },
      declaration: {
        label: 'Declaration'
      }
    }
  }
} as const;

export default terminology;
