import { j as t } from "../../jsx-runtime-NmMXPjeo.js";
import { useEffect as I, createContext as A, useState as w, useMemo as E, useContext as T } from "react";
import s, { keyframes as P } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getSpacings as o, getPaletteBorder as k, getRadii as D, getPaletteBackground as _, getEffects as z, getBreakpointMediaQuery as u, getZIndexes as S } from "../Theme/utils.es.js";
import "../CssBaseline/index.es.js";
import B from "../IconButton/index.es.js";
import { I as M } from "../../Icon-qMvz1KAz.js";
import "../Icon/countryVariants.es.js";
import R from "../FeaturedIcon/index.es.js";
import "../FeaturedIcon/constants.es.js";
import "../../styles-YtnfNTLW.js";
import b from "../Typography/index.es.js";
import { notificationItemAnimationKeyframes as q, notificationListStyles as L } from "./styles.es.js";
import { STATE_VARIANT_MAPPER as F } from "./constants.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../IconButton/styles.es.js";
import "../Button/styles.es.js";
import "../Icon/variants.es.js";
import "../FeaturedIcon/styles.es.js";
import "../FeaturedIcon/outlinedStyles.es.js";
import "../FeaturedIcon/fileIconStyles.es.js";
import "../FeaturedIcon/utils.es.js";
import "../../clsx.m-7d8NmkQq.js";
const O = s.div`
  display: flex;
  position: relative;
  width: 400px;
  box-sizing: border-box;
  
  animation-timing-function: ease-in-out;
  animation-duration: 300ms;
  
  animation-name: ${(i) => q[i.$position]};
  
  padding: ${o("xl")};
  border: 1px solid ${k("primary")};
  border-radius: ${D("xl")};
  background: ${_("primary.default")};
  box-shadow: ${z("shadows.lg")};
  
  ${u("max", "lg")} {
    width: 100%;
  }
`, U = s.div`
  display: flex;
  flex-direction: row;
  
  padding-right: ${o("4xl")};
  gap: ${o("xl")};
  
  ${u("max", "lg")} {
    flex-direction: column;
  }
`, H = s.div`
  display: flex;
  flex-direction: column;
  
  gap: ${o("lg")};
`, K = s.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  
  gap: ${o("xs")};
`, Q = s(B)`
  position: absolute;
  top: 8px;
  right: 8px;
`, V = (i) => {
  const {
    title: l,
    message: r,
    variant: e,
    className: f,
    position: c = "bottom-left",
    children: d,
    autoCloseDuration: a,
    onClose: m
  } = i;
  return I(() => {
    let p;
    return m && a && (p = setTimeout(() => {
      m();
    }, a)), () => {
      clearTimeout(p);
    };
  }, []), /* @__PURE__ */ t.jsxs(
    O,
    {
      $position: c,
      className: f,
      children: [
        /* @__PURE__ */ t.jsx(
          Q,
          {
            variant: "ghost",
            color: "gray",
            onClick: m,
            children: /* @__PURE__ */ t.jsx(
              M,
              {
                variant: "close"
              }
            )
          }
        ),
        /* @__PURE__ */ t.jsxs(U, { children: [
          e && /* @__PURE__ */ t.jsx(
            R,
            {
              size: "sm",
              variant: "outlined",
              color: e,
              iconProps: {
                variant: F[e]
              }
            }
          ),
          /* @__PURE__ */ t.jsxs(H, { children: [
            /* @__PURE__ */ t.jsxs(K, { children: [
              /* @__PURE__ */ t.jsx(
                b,
                {
                  size: "sm",
                  weight: "semibold",
                  children: l
                }
              ),
              r && /* @__PURE__ */ t.jsx(
                b,
                {
                  size: "sm",
                  weight: "regular",
                  children: r
                }
              )
            ] }),
            d
          ] })
        ] })
      ]
    }
  );
}, Z = P`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`, G = s.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  position: fixed;
  background: linear-gradient(314deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.00)47.44%);
  
  animation: ease-in-out ${Z} 150ms;
  
  gap: ${o("3xl")};
  padding-top: ${o("3xl")};
  padding-bottom: ${o("3xl")};
  z-index: ${S("notification")};
  
  ${u("max", "lg")} {
    width: 100%;
    background: linear-gradient(357deg, rgba(0, 0, 0, 0.10) 2.75%, rgba(0, 0, 0, 0.00) 92.77%);
    
    gap: ${o("xl")};
    padding-top: ${o("xl")};
    padding-bottom: ${o("xl")};
    
    padding-left: ${o("md")};
    padding-right: ${o("md")};
  };
  
  ${(i) => L[i.$position]};
`, J = (i) => {
  const {
    children: l,
    className: r,
    position: e = "bottom-left"
  } = i;
  return /* @__PURE__ */ t.jsx(
    G,
    {
      $position: e,
      className: r,
      children: l
    }
  );
}, y = A({
  enqueueNotification: () => {
  },
  removeAllNotifications: () => {
  }
}), W = 8e3, _t = (i) => {
  const {
    children: l,
    className: r,
    position: e = "bottom-left",
    autoHideDuration: f = W
  } = i, [
    c,
    d
  ] = w({}), a = E(() => Object.values(c), [c]), m = (n) => {
    const {
      onClose: h,
      ...N
    } = n, x = Date.now(), v = (g) => {
      d((C) => {
        const $ = {
          ...C
        };
        return delete $[g], $;
      });
    }, j = () => {
      h && h(), v(x);
    };
    d((g) => ({
      ...g,
      [x]: {
        autoCloseDuration: f,
        ...N,
        id: x,
        onClose: j
      }
    }));
  }, p = () => {
    a.forEach(({ onClose: n }) => {
      n && n();
    });
  };
  return /* @__PURE__ */ t.jsxs(
    y.Provider,
    {
      value: {
        enqueueNotification: m,
        removeAllNotifications: p
      },
      children: [
        l,
        a.length > 0 && /* @__PURE__ */ t.jsx(
          J,
          {
            className: r,
            position: e,
            children: a.map((n) => /* @__PURE__ */ t.jsx(
              V,
              {
                ...n,
                position: e
              },
              n.id
            ))
          }
        )
      ]
    }
  );
}, zt = () => T(y);
export {
  _t as default,
  zt as useNotification
};
