import { css as t, keyframes as e } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getSpacings as i } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-NmMXPjeo.js";
import "../CssBaseline/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
const o = t`
  padding-left: ${i("8xl")};
  padding-right: ${i("xl")};
`, r = t`
  padding-left: ${i("3xl")};
  padding-right: ${i("8xl")};
`, L = {
  "top-left": t`
    top: 0;
    left: 0;
    
    ${o};
  `,
  "bottom-left": t`
    bottom: 0;
    left: 0;
    
    ${o};
  `,
  "top-right": t`
    top: 0;
    right: 0;
    
    ${r};
  `,
  "bottom-right": t`
    bottom: 0;
    right: 0;
    
    ${r};
  `
}, m = e`
  0% {
    right: 100%;
  }
  
  100% {
    right: 0;
  }
`, p = e`
  0% {
    left: 100%;
  }
  
  100% {
    left: 0;
  }
`, j = {
  "top-left": m,
  "bottom-left": m,
  "top-right": p,
  "bottom-right": p
};
export {
  j as notificationItemAnimationKeyframes,
  L as notificationListStyles
};
