import { ThemeContextProvider, ThemeConfig } from '@oncore/shared';
import useSettings from '../../hooks/useSettings';


export type Props = {
  children?: React.ReactNode;
};

export const OncoreFlexThemeProvider: React.FC<Props> = (props) => {
  const {
    children
  } = props;

  const { settings } = useSettings();

  const config: ThemeConfig = {
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  };

  return (
    <ThemeContextProvider config={config}>
      {children}
    </ThemeContextProvider>
  );
};

export default OncoreFlexThemeProvider;
