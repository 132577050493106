import { css as i } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getSpacings as t } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-NmMXPjeo.js";
import "../CssBaseline/index.es.js";
import { variantStyles as p } from "../Button/styles.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
const b = p, j = {
  xs: i`
    padding: ${t("md")};
    
    > svg {
      width: 16px;
      height: 16px;
    }
  `,
  sm: i`
    padding: ${t("md")};

    > svg {
      width: 20px;
      height: 20px;
    }
  `,
  md: i`
    padding: 10px;

    > svg {
      width: 20px;
      height: 20px;
    }
  `,
  lg: i`
    padding: ${t("lg")};

    > svg {
      width: 20px;
      height: 20px;
    }
  `,
  xl: i`
    padding: 14px;
    
    > svg {
      width: 20px;
      height: 20px;
    }
  `,
  "2xl": i`
    padding: ${t("xl")};

    > svg {
      width: 24px;
      height: 24px;
    }
  `
};
export {
  j as sizeStyles,
  b as variantStyles
};
