import { lazy as r } from "react";
const t = {
  // We anticipate this to grow very big. For ease of maintenance,
  // it is recommended to maintain alphabetical sorting of the keys
  "alert-circle": r(() => import("../../alert-circle-qvqWkEpA.js")),
  "arrow-left": r(() => import("../../arrow-left-q-WprgRi.js")),
  "bar-chart-square": r(
    () => import("../../bar-chart-square-SCOzJbZh.js")
  ),
  building: r(() => import("../../building-03-1HBGs2Lx.js")),
  calendar: r(() => import("../../calendar-tfoOW9jf.js")),
  "calendar-plus": r(() => import("../../calendar-plus-j-hbfUar.js")),
  check: r(() => import("../../check-k8HgNBz1.js")),
  "check-circle": r(() => import("../../check-circle-cvKXyXOa.js")),
  "check-circle-broken": r(
    () => import("../../check-circle-broken-nRVOxE1k.js")
  ),
  "chevron-down": r(() => import("../../chevron-down-HtI2oY8o.js")),
  "chevron-left": r(() => import("../../chevron-left-GK5NgfVL.js")),
  "chevron-right": r(() => import("../../chevron-right-b0gG9fIp.js")),
  "chevron-up": r(() => import("../../chevron-up-FEV8obbU.js")),
  "chevron-selector-horizontal": r(() => import("../../chevron-selector-horizontal-PPPptqa-.js")),
  "chevron-selector-vertical": r(() => import("../../chevron-selector-vertical-wnFlgJ7V.js")),
  clock: r(() => import("../../clock-M9ifn_lB.js")),
  "code-01": r(() => import("../../code-01-gSqGTV2b.js")),
  "code-02": r(() => import("../../code-02-cGEcbAkE.js")),
  close: r(() => import("../../x-close-hnxP8zu5.js")),
  "currency-dollar": r(() => import("../../currency-dollar-j11PvPx-.js")),
  document: r(() => import("../../document-222TsdFV.js")),
  "download-01": r(() => import("../../download-01-8mT9sxrR.js")),
  "download-02": r(() => import("../../download-02-VtVrOG6s.js")),
  "download-03": r(() => import("../../download-03-sud4ZYlM.js")),
  "download-04": r(() => import("../../download-04-9uzId2Jr.js")),
  "file-attachment": r(() => import("../../file-attachment-wh9LlbZo.js")),
  "film-01": r(() => import("../../film-01-0d64DBV4.js")),
  "filter-lines": r(() => import("../../filter-lines-z0NSCl6c.js")),
  folder: r(() => import("../../folder-epTXxvty.js")),
  home: r(() => import("../../home-GVclYqyz.js")),
  "image-05": r(() => import("../../image-05-g_loP-12.js")),
  "info-circle": r(
    () => import("../../info-circle-0Csop5q3.js")
  ),
  "log-out": r(() => import("../../log-out-01-zhDQzTkK.js")),
  "marker-pin": r(() => import("../../marker-pin-MFsStpiY.js")),
  menu: r(() => import("../../menu-02-FTSiMy35.js")),
  minus: r(() => import("../../minus-ao16hNFy.js")),
  "minus-circle": r(() => import("../../minus-circle-j8IoDAjP.js")),
  "music-note-01": r(() => import("../../music-note-01-mQ7gyME4.js")),
  "percent-01": r(() => import("../../percent-01-xjSXrW7x.js")),
  "play-circle": r(() => import("../../play-circle-zRJZlG3w.js")),
  "plus-circle": r(() => import("../../plus-circle-MEDQEzKB.js")),
  "portable-document-format": r(() => import("../../portable-document-format-XkAzXXmK.js")),
  receipt: r(() => import("../../receipt-Ra-wJHNc.js")),
  "receipt-check": r(() => import("../../receipt-check-6K8roPZ2.js")),
  "search-lg": r(() => import("../../search-lg-4q2_w8g-.js")),
  spreadsheet: r(() => import("../../spreadsheet-Do83J40Q.js")),
  "stars-02": r(() => import("../../stars-02-a2Lgbw-1.js")),
  "trash-01": r(() => import("../../trash-01-LYzJl78b.js")),
  "trash-02": r(() => import("../../trash-02-XmvzXlqt.js")),
  "trash-03": r(() => import("../../trash-03-rstUBnIO.js")),
  "trash-04": r(() => import("../../trash-04-H3X6BfSL.js")),
  "upload-cloud-01": r(() => import("../../upload-cloud-01-hRe-JCEi.js")),
  "upload-cloud-02": r(() => import("../../upload-cloud-02-FVLCG7-p.js")),
  user: r(() => import("../../user-01-ZTRC0akk.js")),
  users: r(() => import("../../users-01-IrW_fNJ2.js")),
  "users-2": r(() => import("../../users-02-qbR3Fz9f.js")),
  "user-plus": r(() => import("../../user-plus-6ZYktxox.js")),
  "user-check": r(() => import("../../user-check-LH9pY4QL.js")),
  "user-square": r(() => import("../../user-square-DZh3X9xz.js")),
  "x-circle": r(() => import("../../x-circle-ECVjrMDh.js"))
};
export {
  t as default
};
