import { l as r } from "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
const n = () => (t) => r(t, "theme"), s = (t) => (e) => r(n()(e), `palette.${t}`), l = (t) => (e) => r(n()(e), `palette.text.${t}`), i = (t) => (e) => r(n()(e), `palette.background.${t}`), p = (t) => (e) => r(n()(e), `palette.foreground.${t}`), d = (t) => (e) => r(n()(e), `palette.border.${t}`), $ = (t) => (e) => r(s("colors")(e), t), m = (t) => (e) => r(s("base")(e), t), P = (t) => (e) => r(n()(e), `spacings.${t}`), f = (t) => (e) => r(n()(e), `widths.${t}`), h = (t) => (e) => r(n()(e), `radii.${t}`), k = (t) => (e) => r(n()(e), `effects.${t}`), B = (t) => (e) => r(n()(e), `zIndexes.${t}`), c = (t, e) => (o) => {
  const a = r(n()(o), `breakpoints.${e}`);
  return `(${t}-width: ${a})`;
}, x = (t, e) => (o) => `@media ${c(t, e)(o)}`, b = (t) => (e) => r(n()(e), `components.${t}`);
export {
  c as getBreakpoint,
  x as getBreakpointMediaQuery,
  b as getComponents,
  k as getEffects,
  s as getPalette,
  i as getPaletteBackground,
  m as getPaletteBase,
  d as getPaletteBorder,
  $ as getPaletteColors,
  p as getPaletteForeground,
  l as getPaletteText,
  h as getRadii,
  P as getSpacings,
  n as getTheme,
  f as getWidths,
  B as getZIndexes
};
