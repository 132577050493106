import { j as m } from "../../jsx-runtime-NmMXPjeo.js";
import n from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getRadii as p, getPaletteForeground as s } from "../Theme/utils.es.js";
import "react";
import "../CssBaseline/index.es.js";
import { variantStyles as a, sizeStyles as l } from "./styles.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
import "../Button/styles.es.js";
const d = n.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  border-radius: ${p("full")};
  
  ${(t) => a[t.$variant]}
  ${(t) => l[t.$size]}
  
  &:disabled {
    cursor: not-allowed;
    
    color: ${s("disabled.default")};
  }
`, q = (t) => {
  const {
    variant: o = "contained",
    color: r = "brand",
    size: i = "md",
    ...e
  } = t;
  return /* @__PURE__ */ m.jsx(
    d,
    {
      $variant: o,
      $color: r,
      $size: i,
      ...e
    }
  );
};
export {
  q as default
};
