import { j as s } from "../../jsx-runtime-NmMXPjeo.js";
import n from "styled-components";
import { c as d } from "../../clsx.m-7d8NmkQq.js";
import g from "../Avatar/index.es.js";
import { t } from "../../styles-YtnfNTLW.js";
import "../Typography/index.es.js";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getPaletteText as r } from "../Theme/utils.es.js";
import "react";
import "../CssBaseline/index.es.js";
import "../../polished.esm-wTGEn2h0.js";
import "../../isExhaustive-ua1FyxmG.js";
import "../../getInitials-mV6BUugk.js";
import "../../Icon-qMvz1KAz.js";
import "../Icon/variants.es.js";
import "../Icon/countryVariants.es.js";
import "../Avatar/styles.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../Theme/colors.es.js";
import "../CssBaseline/normalize.es.js";
const v = n.div`
  display: flex;
  align-items: center;
  
  text-align: left;
  
  > div.texts {
    min-width: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
  }
  
  > div.texts span.text, > div.texts span.supportingText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div.texts span.text {
    color: ${r("secondary.default")};
  }

  > div.texts span.supportingText {
    color: ${r("tertiary.default")};
  }
  
  &.size-sm {
    gap: 10px;

    > div.texts span.text {
      ${t.variants.text.sm}
      ${t.weights.semibold}
    }

    > div.texts span.supportingText {
      ${t.variants.text.xs}
      ${t.weights.regular}
    }
  }
  
  &.size-md {
    gap: 12px;

    > div.texts span.text {
      ${t.variants.text.sm}
      ${t.weights.semibold}
    }

    > div.texts span.supportingText {
      ${t.variants.text.sm}
      ${t.weights.regular}
    }
  }

  &.size-lg {
    gap: 12px;

    > div.texts span.text {
      ${t.variants.text.md}
      ${t.weights.semibold}
    }

    > div.texts span.supportingText {
      ${t.variants.text.md}
      ${t.weights.regular}
    }
  }

  &.size-xl {
    gap: 16px;

    > div.texts span.text {
      ${t.variants.text.lg}
      ${t.weights.semibold}
    }

    > div.texts span.supportingText {
      ${t.variants.text.md}
      ${t.weights.regular}
    }
  }
`, c = {
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl"
}, M = (a) => {
  const {
    size: e = "sm",
    avatar: i,
    text: p,
    supportingText: o,
    className: m,
    ...x
  } = a, l = d(`size-${e}`, m);
  return /* @__PURE__ */ s.jsxs(v, { ...x, className: l, children: [
    /* @__PURE__ */ s.jsx(g, { ...i, size: i.size ?? c[e] }),
    /* @__PURE__ */ s.jsxs("div", { className: "texts", children: [
      /* @__PURE__ */ s.jsx("span", { className: "text", children: p }),
      /* @__PURE__ */ s.jsx("span", { className: "supportingText", children: o })
    ] })
  ] });
};
export {
  M as default
};
