import { useState as d, useEffect as h } from "react";
import { useTheme as p } from "styled-components";
import { getBreakpoint as f } from "./utils.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
const n = (e) => typeof window < "u" ? window.matchMedia(e).matches : !1, u = (e, o) => e === "mobile" || e === "desktop" ? f("max", "lg")({
  theme: o
}) : e, b = (e) => {
  const o = p(), t = u(e, o), i = e === "desktop" ? !n(t) : n(t), [
    a,
    c
  ] = d(i);
  return h(() => {
    const r = window.matchMedia(t), s = () => {
      const m = e === "desktop" ? !n(t) : n(t);
      c(m);
    };
    return r.addEventListener("change", s), () => {
      r.removeEventListener("change", s);
    };
  }, [t]), a;
};
export {
  b as useMediaQuery
};
