import { Provider } from 'react-redux';
import { RelayEnvironmentProvider } from 'relay-hooks';
import { QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider, useEnvironment } from '@oncore/shared';
import ThemeProvider from '@oncore/ui/Theme';
import environment from './environment';
import store from './state/store';
import OncoreFlexRouter from './components/OncoreFlexRouter';
import GlobalStyles from './components/GlobalStyles';
import { queryClient } from './clients';
import useLegacyStateTransition from './hooks/useLegacyStateTransition';
import Loader from 'src/spike/Loader';
import OncoreNotification from 'src/spike/OncoreNotification';
import { LoggingProvider } from '@oncore/ui/shared/Logging';

export type Props = {
  children?: never;
};

export const App = (_: Props) => {

  useLegacyStateTransition();

  const { seq } = useEnvironment();

  return (
    <LoggingProvider
      serverUrl={seq?.url}
      apiKey={seq?.key}
      redirectError
    >
      <Loader>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <SnackbarProvider>
              <OncoreNotification>
                <RelayEnvironmentProvider environment={environment}>
                  <GlobalStyles />
                  <OncoreFlexRouter />
                </RelayEnvironmentProvider>
              </OncoreNotification>
            </SnackbarProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </Loader>
    </LoggingProvider>
  );
};

const AppWithProvider = (_: Props) => {

  return (
    <Provider store={store}>
      <App/>
    </Provider>
  );
};

export default AppWithProvider;
