import { lazy as t } from "react";
const o = {
  AD: t(() => import("../../AD-FQiE3E4e.js")),
  AE: t(() => import("../../AE-mS7cy4K1.js")),
  AF: t(() => import("../../AF-yI4W6Xb3.js")),
  AG: t(() => import("../../AG-0zePghwM.js")),
  AI: t(() => import("../../AI-AT3q4amv.js")),
  AL: t(() => import("../../AL-URqpgh24.js")),
  AM: t(() => import("../../AM-fjs9H37q.js")),
  AO: t(() => import("../../AO-CK5Lrl_J.js")),
  AR: t(() => import("../../AR-IbUZuM6_.js")),
  AS: t(() => import("../../AS-Kb6UqSIb.js")),
  AT: t(() => import("../../AT--b-yeQCh.js")),
  AU: t(() => import("../../AU-k7VrpIkb.js")),
  AW: t(() => import("../../AW-V3JMEzde.js")),
  AX: t(() => import("../../AX-b9-3zY3V.js")),
  AZ: t(() => import("../../AZ-mTPCF19b.js")),
  BA: t(() => import("../../BA-LDXgwQ2C.js")),
  BB: t(() => import("../../BB-_kISj8oS.js")),
  BD: t(() => import("../../BD-VvE1JgF0.js")),
  BE: t(() => import("../../BE-6JbjRm5Q.js")),
  BF: t(() => import("../../BF-SjaR_oEu.js")),
  BG: t(() => import("../../BG-ufMp7FXC.js")),
  BH: t(() => import("../../BH-twkaHAOO.js")),
  BI: t(() => import("../../BI-pFHXDSTf.js")),
  BJ: t(() => import("../../BJ-WJPE93En.js")),
  BL: t(() => import("../../BL-zXtz4B09.js")),
  BM: t(() => import("../../BM-GEUqi07f.js")),
  BN: t(() => import("../../BN-4mFtM7WW.js")),
  BO: t(() => import("../../BO-p1ZT84Ks.js")),
  BQ: t(() => import("../../BQ-EcJF3Ak5.js")),
  "BQ-1": t(() => import("../../BQ-1-yty6CJ1Q.js")),
  "BQ-2": t(() => import("../../BQ-2-YfgT_FQY.js")),
  BR: t(() => import("../../BR-taW_QD2D.js")),
  BS: t(() => import("../../BS-riyhjUCR.js")),
  BT: t(() => import("../../BT-C2RIlMKr.js")),
  BW: t(() => import("../../BW-bQj1-NtQ.js")),
  BY: t(() => import("../../BY-u1FfFmH0.js")),
  BZ: t(() => import("../../BZ-yYALvYpi.js")),
  CA: t(() => import("../../CA-ITrL4eSw.js")),
  CC: t(() => import("../../CC-0VGnyjAt.js")),
  CD: t(() => import("../../CD-w8MTiOJr.js")),
  "CD-1": t(() => import("../../CD-1-SKR8g4w4.js")),
  CF: t(() => import("../../CF-UHzFZEYY.js")),
  CH: t(() => import("../../CH-v72_zRBi.js")),
  CK: t(() => import("../../CK-stxf-J90.js")),
  CL: t(() => import("../../CL-GY2gmSbk.js")),
  CM: t(() => import("../../CM-xHULy3WO.js")),
  CN: t(() => import("../../CN-JsJWoHps.js")),
  CO: t(() => import("../../CO-oBjMT6EJ.js")),
  CR: t(() => import("../../CR-fcrA3Tmi.js")),
  CU: t(() => import("../../CU-hOQdC7-I.js")),
  CW: t(() => import("../../CW-2G7maa8G.js")),
  CX: t(() => import("../../CX-aXYEreF2.js")),
  CY: t(() => import("../../CY-Q-CJVk5G.js")),
  CZ: t(() => import("../../CZ-C5V0Q7jv.js")),
  DE: t(() => import("../../DE-5c-xHjig.js")),
  DJ: t(() => import("../../DJ-GeK4wp2l.js")),
  DK: t(() => import("../../DK-IRa1odHk.js")),
  DM: t(() => import("../../DM-gPaENUkV.js")),
  DO: t(() => import("../../DO-2IxGJlOA.js")),
  DS: t(() => import("../../DS-rzJvsgNJ.js")),
  DZ: t(() => import("../../DZ-K9SYEfw9.js")),
  earth: t(() => import("../../earth-y_DTIpCf.js")),
  EC: t(() => import("../../EC-7_6nJax9.js")),
  EE: t(() => import("../../EE-RnYCdST2.js")),
  EG: t(() => import("../../EG-W-98_QqC.js")),
  EH: t(() => import("../../EH-a998a13T.js")),
  ER: t(() => import("../../ER-UZ2wvCB0.js")),
  ES: t(() => import("../../ES-tL83KXlF.js")),
  ET: t(() => import("../../ET-Tt_Z5ori.js")),
  FI: t(() => import("../../FI-j67DPfHD.js")),
  FJ: t(() => import("../../FJ-YqFNGjbS.js")),
  FK: t(() => import("../../FK-GPYkt4p_.js")),
  FM: t(() => import("../../FM-PxonZJd5.js")),
  FO: t(() => import("../../FO-c62MtABD.js")),
  FR: t(() => import("../../FR-Jt2mcDyj.js")),
  GA: t(() => import("../../GA-IQGBUfem.js")),
  GB: t(() => import("../../GB-WQY6RwUa.js")),
  "GB-2": t(() => import("../../GB-2-dDWKelyY.js")),
  GD: t(() => import("../../GD-9BSdifYI.js")),
  GE: t(() => import("../../GE-K00Qr6YG.js")),
  GG: t(() => import("../../GG-cDv6ud5j.js")),
  GH: t(() => import("../../GH-He25cd5n.js")),
  GI: t(() => import("../../GI-6Ii6JoZd.js")),
  GL: t(() => import("../../GL-Ow_7mfV_.js")),
  GM: t(() => import("../../GH-He25cd5n.js")),
  GN: t(() => import("../../GN-adWfmg27.js")),
  GQ: t(() => import("../../GQ-jdgsEkHK.js")),
  GR: t(() => import("../../GR-FwA_vlTE.js")),
  GT: t(() => import("../../GT-oDlWifnc.js")),
  GU: t(() => import("../../GU-EjcS91CK.js")),
  GW: t(() => import("../../GW-vn-Mo298.js")),
  GY: t(() => import("../../GY-Vlu84VEv.js")),
  HK: t(() => import("../../HK-RxBEPMLN.js")),
  HN: t(() => import("../../HN--qMDYLgM.js")),
  HR: t(() => import("../../HR-8GGuFZ8z.js")),
  HT: t(() => import("../../HT-Iy0czlrG.js")),
  HU: t(() => import("../../HU--nvih82X.js")),
  ID: t(() => import("../../ID-sPMw7a4N.js")),
  IE: t(() => import("../../IE-8fOX5aqr.js")),
  IL: t(() => import("../../IL-bVDAOSZC.js")),
  IM: t(() => import("../../IM-IRw2qdho.js")),
  IN: t(() => import("../../IN-FqSzTanL.js")),
  IO: t(() => import("../../IO-68wRP5UU.js")),
  IQ: t(() => import("../../IQ-tuqFWQ_t.js")),
  IR: t(() => import("../../IR-QyqzqNja.js")),
  IS: t(() => import("../../IS---DZTLwH.js")),
  IT: t(() => import("../../IT-44si_dvv.js")),
  JE: t(() => import("../../JE-bg60LqOT.js")),
  JM: t(() => import("../../JM-C31MJ9hM.js")),
  JO: t(() => import("../../JO-B2CxuD5s.js")),
  JP: t(() => import("../../JP-ZJiRpf1h.js")),
  KE: t(() => import("../../KE-XVvpJ4m4.js")),
  KG: t(() => import("../../KG-HvayZAxD.js")),
  KH: t(() => import("../../KH-5mh_04HI.js")),
  KI: t(() => import("../../KI-1kY3oedq.js")),
  KM: t(() => import("../../KM-jtMnZHT_.js")),
  KN: t(() => import("../../KN-zAg63BJN.js")),
  KP: t(() => import("../../KP-yxS3wgEd.js")),
  KR: t(() => import("../../KR-U05YaDBp.js")),
  KW: t(() => import("../../KW-8YkF0fku.js")),
  KY: t(() => import("../../KY-lbCRsmDE.js")),
  KZ: t(() => import("../../KZ-3FgtWnIF.js")),
  LA: t(() => import("../../LA-FDtgDAhx.js")),
  LB: t(() => import("../../LB-4rXtqUyL.js")),
  LC: t(() => import("../../LC-r4og5JBz.js")),
  LI: t(() => import("../../LI-tL_1V_vr.js")),
  LK: t(() => import("../../LK-0HIMK44i.js")),
  LR: t(() => import("../../LR-fAuxKOin.js")),
  LS: t(() => import("../../LS-2AP4lIPr.js")),
  LT: t(() => import("../../LT-_2e79G5i.js")),
  LU: t(() => import("../../LU-v0c8z_pn.js")),
  LV: t(() => import("../../LV-unxTPRwn.js")),
  LY: t(() => import("../../LY-8F-WcWWB.js")),
  MA: t(() => import("../../MA-DzoPgQvi.js")),
  MC: t(() => import("../../MC-Xdws5eQ1.js")),
  MD: t(() => import("../../MD-C68u7dx_.js")),
  ME: t(() => import("../../ME-TCooJjuE.js")),
  MG: t(() => import("../../MG-c5KAExCh.js")),
  MH: t(() => import("../../MH-q8n-6Ucl.js")),
  MK: t(() => import("../../MK-B1PMqPtO.js")),
  ML: t(() => import("../../ML-IGXlVfvP.js")),
  MM: t(() => import("../../MM-PGYteCZ9.js")),
  MN: t(() => import("../../MN-gb5LkMI2.js")),
  MO: t(() => import("../../MO-cXaswEqf.js")),
  MP: t(() => import("../../MP-KWZ7DE2E.js")),
  MQ: t(() => import("../../MQ-VsvB91_c.js")),
  MR: t(() => import("../../MR-TDAYY1eL.js")),
  MS: t(() => import("../../MS-5a0JtNwL.js")),
  MT: t(() => import("../../MT-Hj_OfQ9x.js")),
  MU: t(() => import("../../MU-UgTBof0d.js")),
  MV: t(() => import("../../MV-yjBT8whk.js")),
  MW: t(() => import("../../MW-aELIgAuv.js")),
  MX: t(() => import("../../MX-bViGsoho.js")),
  MY: t(() => import("../../MY-ItiJtQBg.js")),
  MZ: t(() => import("../../MZ-HTiV6tjn.js")),
  NA: t(() => import("../../NA-WRhW7iri.js")),
  NE: t(() => import("../../NE-kcx5tKI4.js")),
  NF: t(() => import("../../NF-YIZcLSk6.js")),
  NG: t(() => import("../../NG-qUnkVxyj.js")),
  NI: t(() => import("../../NI-J1Ytz3Fs.js")),
  NL: t(() => import("../../NL-4h8xE7kI.js")),
  NO: t(() => import("../../NO-kSI5iSFq.js")),
  NP: t(() => import("../../NP-1UTqurc5.js")),
  NR: t(() => import("../../NR-qg3coDmH.js")),
  NU: t(() => import("../../NU-0s_wNAm8.js")),
  NZ: t(() => import("../../NZ-6-JG8ACB.js")),
  OM: t(() => import("../../OM-t1ElOmca.js")),
  PA: t(() => import("../../PA-tTlZYnqG.js")),
  PE: t(() => import("../../PE-E7qGei-i.js")),
  PF: t(() => import("../../PF-WWK7lTNb.js")),
  PG: t(() => import("../../PG-kWT741R9.js")),
  PH: t(() => import("../../PH-aM-jiipm.js")),
  PK: t(() => import("../../PK-39QDMHKf.js")),
  PL: t(() => import("../../PL-65SGTZtd.js")),
  PN: t(() => import("../../PN-lJxEOEOr.js")),
  PR: t(() => import("../../PR-xKCBbdWi.js")),
  PS: t(() => import("../../PS-BoMkTKDf.js")),
  PT: t(() => import("../../PT-ZJgsgAEs.js")),
  PW: t(() => import("../../PW-s6qj87AO.js")),
  PY: t(() => import("../../PY-ESrv5C3g.js")),
  QA: t(() => import("../../QA-I_2S4Eyh.js")),
  RO: t(() => import("../../RO-nXbdDle5.js")),
  RS: t(() => import("../../RS-NaCQg2uP.js")),
  RU: t(() => import("../../RU-87fmMq71.js")),
  RW: t(() => import("../../RW-RYpJsgmX.js")),
  SA: t(() => import("../../SA-y_0JPsSj.js")),
  SB: t(() => import("../../SB-p5DV0FRM.js")),
  SC: t(() => import("../../SC-IVZ2aKLm.js")),
  SE: t(() => import("../../SE-Fmr745Z2.js")),
  SG: t(() => import("../../SG-MyXaxieb.js")),
  SI: t(() => import("../../SI-O6pItxka.js")),
  SK: t(() => import("../../SK-N0OF7N_L.js")),
  SL: t(() => import("../../SL-IiUJ7FNF.js")),
  SM: t(() => import("../../SM-OOjEW7jF.js")),
  SN: t(() => import("../../SN-V3iKpWdJ.js")),
  SO: t(() => import("../../SO-XbXNfI93.js")),
  SR: t(() => import("../../SR-dvZgz7_h.js")),
  SS: t(() => import("../../SS-L5cFmNyV.js")),
  ST: t(() => import("../../ST-f7MRLcWa.js")),
  SV: t(() => import("../../SV-g4fBD2pU.js")),
  SX: t(() => import("../../SX-OxTNUeDP.js")),
  SY: t(() => import("../../SY-P62O0HT-.js")),
  SZ: t(() => import("../../SZ--0gaICuR.js")),
  TC: t(() => import("../../TC-ht4gk5VX.js")),
  TD: t(() => import("../../TD-hKz5WBKF.js")),
  TG: t(() => import("../../TG-kaSdFTfn.js")),
  TH: t(() => import("../../TH-p-NwFqDQ.js")),
  TJ: t(() => import("../../TJ-X85M3FWl.js")),
  TK: t(() => import("../../TK-4VIIrFEE.js")),
  TL: t(() => import("../../TL-X6HB3Vkr.js")),
  TM: t(() => import("../../TM-x2YUQJdZ.js")),
  TN: t(() => import("../../TN-JMP6Go3k.js")),
  TO: t(() => import("../../TO-sSPJwW3C.js")),
  TR: t(() => import("../../TR-5OpVNa6i.js")),
  TT: t(() => import("../../TT-TutOJViD.js")),
  TV: t(() => import("../../TV-uu969Ntv.js")),
  TW: t(() => import("../../TW-eyoPQjdx.js")),
  TZ: t(() => import("../../TZ-2fsyeEXD.js")),
  UA: t(() => import("../../UA-4--Yuk_F.js")),
  UG: t(() => import("../../UG-BcH4JBdN.js")),
  US: t(() => import("../../US-plxeMK02.js")),
  UY: t(() => import("../../UY-uXfN2WKZ.js")),
  UZ: t(() => import("../../UZ-R8CDe-gy.js")),
  VC: t(() => import("../../VC-gRahicBm.js")),
  VE: t(() => import("../../VE-rugxIFgJ.js")),
  VG: t(() => import("../../VG-wFixlW34.js")),
  VI: t(() => import("../../VI-Le8J4iiz.js")),
  VN: t(() => import("../../VN-40oZDekW.js")),
  VU: t(() => import("../../VU-HJcS_gdR.js")),
  WS: t(() => import("../../WS-WkVlpVRN.js")),
  YE: t(() => import("../../YE-EXF2HRir.js")),
  ZA: t(() => import("../../ZA-x-kv9vaF.js")),
  ZM: t(() => import("../../ZM-I-bAntBL.js")),
  ZW: t(() => import("../../ZW-AX9aa33x.js"))
};
export {
  o as default
};
