import { css as r } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getSpacings as o, getPaletteForeground as t, getPaletteBackground as e, getPaletteText as i } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-NmMXPjeo.js";
import "../CssBaseline/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
const q = {
  root: r`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    gap: 8px;

    padding: ${o("md")} ${o("lg")};
    cursor: pointer;

    border: none;
    border-radius: 6px;

    color: ${t("quarterary.default")};
    background-color: ${e("primary.default")};

    text-decoration: none;

    span {
      color: ${i("secondary.default")};
    }
  `,
  active: r`
    color: ${t("quarterary.hover")};
    background-color: ${e("primary.hover")};

    span {
      color: ${i("secondary.hover")};
    }
  `
};
export {
  q as sideBarItemStyles
};
