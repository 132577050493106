import { j as o } from "../../jsx-runtime-NmMXPjeo.js";
import x from "styled-components";
import { r as n } from "../../polished.esm-wTGEn2h0.js";
import { i as g } from "../../isExhaustive-ua1FyxmG.js";
import { g as f } from "../../getInitials-mV6BUugk.js";
import { I as y } from "../../Icon-qMvz1KAz.js";
import "react";
import "../Icon/countryVariants.es.js";
import "../../styles-YtnfNTLW.js";
import u from "../Typography/index.es.js";
import { avatarSizeStyles as h } from "./styles.es.js";
import { getRadii as z, getPaletteColors as a, getPaletteForeground as b } from "../Theme/utils.es.js";
import "../Icon/variants.es.js";
import "../../clsx.m-7d8NmkQq.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
const m = x.div`
  border-radius: ${z("full")};

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  
  border-color: ${(r) => n(a("gray.900")(r), 0.08)};
  background-color: ${a("gray.200")};
  color: ${b("quarterary.default")};
  
  ${(r) => h[r.$size]};

  &:focus {
    outline: 4px solid ${(r) => n(a("gray.400")(r), 0.14)};
  }
`, T = (r) => {
  const {
    size: s = "sm",
    className: l,
    ...t
  } = r;
  switch (t.variant) {
    case "icon": {
      const {
        variant: c,
        icon: i,
        ...e
      } = t;
      return /* @__PURE__ */ o.jsx(
        m,
        {
          $size: s,
          className: l,
          ...e,
          children: /* @__PURE__ */ o.jsx(y, { ...i })
        }
      );
    }
    case "initials": {
      const {
        variant: c,
        displayName: i,
        ...e
      } = t, d = r.size === "xxl" ? "display" : "text", p = r.size === "xxl" ? "xs" : r.size;
      return /* @__PURE__ */ o.jsx(
        m,
        {
          $size: s,
          className: l,
          ...e,
          children: /* @__PURE__ */ o.jsx(
            u,
            {
              weight: "semibold",
              color: "placeholder",
              variant: d,
              size: p,
              children: f(i)
            }
          )
        }
      );
    }
    default:
      g(t);
  }
};
export {
  T as default
};
