import { Menu as r } from "@headlessui/react";
import t from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getZIndexes as i, getRadii as e, getEffects as d, getPaletteBorder as m, getPaletteBackground as p } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-NmMXPjeo.js";
import "../CssBaseline/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
const E = t(r.Items)`
  padding: 0;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  flex-direction: column;

  z-index: ${i("modal")};
  border-radius: ${e("md")};
  box-shadow: ${d("shadows.lg")};
  border-color: ${m("secondary")};
  background: ${p("primary.default")};
  display: ${(o) => o.$isHidden ? "none" : "flex"};
  width: ${(o) => o.$isFullWidth ? "100%" : "240px"};
`;
export {
  E as Items
};
