import { t as s } from "../../styles-YtnfNTLW.js";
import { s as R } from "../../styles-YtnfNTLW.js";
import { j as p } from "../../jsx-runtime-NmMXPjeo.js";
import d from "styled-components";
import { c as $ } from "../../clsx.m-7d8NmkQq.js";
import "../Theme/utils.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "react";
const g = d.span`
  &.variant-display {
    &.size-xxl {
      ${s.variants.display.xxl}
    }
    &.size-xl {
      ${s.variants.display.xl}
    }
    &.size-lg {
      ${s.variants.display.lg}
    }
    &.size-md {
      ${s.variants.display.md}
    }
    &.size-sm {
      ${s.variants.display.sm}
    }
    &.size-xs {
      ${s.variants.display.xs}
    }
  }

  &.variant-text {
    &.size-xl {
      ${s.variants.text.xl}
    }
    &.size-lg {
      ${s.variants.text.lg}
    }
    &.size-md {
      ${s.variants.text.md}
    }
    &.size-sm {
      ${s.variants.text.sm}
    }
    &.size-xs {
      ${s.variants.text.xs}
    }
  }

  &.weight-regular {
    ${s.weights.regular}
  }
  &.weight-medium {
    ${s.weights.medium}
  }
  &.weight-semibold {
    ${s.weights.semibold}
  }
  &.weight-bold {
    ${s.weights.bold}
  }
  
  ${(t) => t.$color !== "none" ? s.colors[t.$color] : void 0};
`, b = (t) => {
  const {
    variant: i = "text",
    size: a = "md",
    weight: e = "regular",
    color: r = "primary",
    as: o = "span",
    className: l,
    children: m,
    ...n
  } = t, x = $(
    `variant-${i}`,
    `size-${a}`,
    `weight-${e}`,
    l
  );
  return /* @__PURE__ */ p.jsx(
    g,
    {
      ...n,
      className: x,
      as: o,
      $color: r,
      children: m
    }
  );
};
export {
  b as default,
  R as styles
};
