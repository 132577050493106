import { a as t } from "../../radii-gpKo6wCW.js";
import { a as o } from "../../spacings-72DfHhtO.js";
import { a } from "../../widths-pYuCWRvl.js";
import { a as e } from "../../effects-OUwmcFls.js";
import m from "./zIndexes.es.js";
import { a as r } from "../../breakpoints-Ro4HX8Px.js";
import { l as i, d as s } from "../../palettes-foWluF5S.js";
import "../../polished.esm-wTGEn2h0.js";
import "./colors.es.js";
const p = {
  id: "defaultLightTheme",
  mode: "light",
  palette: i,
  spacings: o,
  widths: a,
  radii: t,
  effects: e,
  zIndexes: m,
  breakpoints: r,
  components: {}
}, k = {
  ...p,
  id: "defaultDarkTheme",
  mode: "dark",
  palette: s,
  components: {}
};
export {
  k as DEFAULT_DARK_THEME,
  p as DEFAULT_LIGHT_THEME
};
