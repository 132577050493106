import { j as o } from "../../jsx-runtime-NmMXPjeo.js";
import n from "styled-components";
import { I as l } from "../../Icon-qMvz1KAz.js";
import { useMemo as y, Suspense as f } from "react";
import "../Icon/countryVariants.es.js";
import { sizeStyles as c, variantStyles as u, borderRadiusStyles as z } from "./styles.es.js";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getPaletteColors as $, getRadii as d } from "../Theme/utils.es.js";
import "../CssBaseline/index.es.js";
import { sizeStyles as v, outerSizeStyles as I, innerSizeStyles as b, iconSizeStyles as g } from "./outlinedStyles.es.js";
import { iconVariantStyles as j, pageIconVariantStyles as S, extensionVariantStyles as h } from "./fileIconStyles.es.js";
import { FILE_ICON_TYPE_COLOR_MAPPER as P, FILE_ICON_EXTENSION_COLOR_MAPPER as m, VARIANT_PAGE_MAPPER as E, FILE_TYPE_ICON_MAPPER as C } from "./constants.es.js";
import { getMIMEExtension as _ } from "./utils.es.js";
import "../Icon/variants.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
const x = n.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  
  ${(i) => v[i.$size]};
  
  color: ${(i) => $(i.$color)(i)[600]};
`, p = n(x)`
  position: absolute;
  opacity: 0.1;
  
  border: 2px solid ${(i) => $(i.$color)(i)[600]};
  border-radius: ${d("full")};
  ${(i) => I[i.$size]};
`, O = n(p)`
  opacity: 0.3;
  
  ${(i) => b[i.$size]};
`, R = n(l)`
  ${(i) => g[i.$size]};
`, N = (i) => {
  const {
    className: e,
    color: s = "brand",
    size: t = "md",
    iconProps: r
  } = i;
  return /* @__PURE__ */ o.jsxs(
    x,
    {
      className: e,
      $size: t,
      $color: s,
      children: [
        /* @__PURE__ */ o.jsx(
          p,
          {
            $size: t,
            $color: s
          }
        ),
        /* @__PURE__ */ o.jsx(
          O,
          {
            $size: t,
            $color: s
          }
        ),
        /* @__PURE__ */ o.jsx(
          R,
          {
            $size: t,
            ...r
          }
        )
      ]
    }
  );
}, A = n.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  
  ${(i) => c[i.$size]};
`, M = n.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  
  ${(i) => u[i.$variant]};
  ${(i) => c[i.$size]};
`, T = n.div`
  position: absolute;
  transform: rotate(15deg);
  left: 20%;
  bottom: 20%;
  
  border-radius: ${d("md")};
  background-color: ${(i) => $(i.$color)(i)[600]};
  
  ${(i) => c[i.$size]};
  ${(i) => z[i.$size]};
`, xi = (i) => {
  const {
    variant: e,
    className: s,
    size: t = "md",
    color: r = "brand",
    iconProps: a
  } = i;
  return e === "outlined" ? /* @__PURE__ */ o.jsx(
    N,
    {
      className: s,
      size: t,
      color: r,
      iconProps: a
    }
  ) : /* @__PURE__ */ o.jsxs(
    A,
    {
      $size: t,
      children: [
        /* @__PURE__ */ o.jsx(
          M,
          {
            $variant: e,
            $color: r,
            $size: t,
            className: s,
            children: /* @__PURE__ */ o.jsx(l, { ...a })
          }
        ),
        e === "glass" && /* @__PURE__ */ o.jsx(
          T,
          {
            $size: t,
            $color: r
          }
        )
      ]
    }
  );
}, F = n.div`
  display: inline-flex;
  position: relative;
  width: 32px;
  height: 40px;
`, L = n.div`
  display: inline-flex;
  position: absolute;
  z-index: 1;
  
  ${(i) => j[i.$variant]};
`, w = n.div`
  
  > svg {
    width: 32px;
    height: 40px;
  }
  
  ${(i) => S[i.$variant]};
`, V = n.span`
  display: inline-flex;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  
  ${(i) => h[i.$variant]};
`, pi = (i) => {
  const {
    variant: e = "primary",
    className: s
  } = i, t = "extension" in i ? i.extension : "mimeType" in i ? _(i.mimeType) : void 0, r = y(() => "type" in i ? P[i.type] : t ? m[t] : "gray", [i]), a = E[e];
  return /* @__PURE__ */ o.jsx(f, { children: /* @__PURE__ */ o.jsxs(
    F,
    {
      $color: r,
      $variant: e,
      className: s,
      children: [
        /* @__PURE__ */ o.jsx(
          w,
          {
            $color: r,
            $variant: e,
            children: /* @__PURE__ */ o.jsx(a, {})
          }
        ),
        "type" in i && /* @__PURE__ */ o.jsx(
          L,
          {
            $color: r,
            $variant: e,
            children: /* @__PURE__ */ o.jsx(
              l,
              {
                variant: C[i.type]
              }
            )
          }
        ),
        t && m[t] && /* @__PURE__ */ o.jsx(
          V,
          {
            $color: r,
            $variant: e,
            children: t
          }
        )
      ]
    }
  ) });
};
export {
  pi as FileIcon,
  N as OutlinedFeaturedIcon,
  xi as default,
  _ as getMIMEExtension
};
