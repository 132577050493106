import { j as o } from "../../jsx-runtime-NmMXPjeo.js";
import { forwardRef as f } from "react";
import m from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getRadii as u, getPaletteForeground as $ } from "../Theme/utils.es.js";
import "../CssBaseline/index.es.js";
import { sizeStyles as x, variantStyles as y, iconSizeStyles as h } from "./styles.es.js";
import { t as g } from "../../styles-YtnfNTLW.js";
import z from "../Typography/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../clsx.m-7d8NmkQq.js";
const S = {
  xs: "xs",
  sm: "sm",
  md: "sm",
  lg: "md",
  xl: "md",
  "2xl": "lg"
}, j = m.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border-radius: ${u("full")};

  ${(t) => x[t.$size]};
  ${(t) => y[t.$variant]};
  
  ${(t) => g.variants.text[S[t.$size]]}

  &:disabled {
    cursor: not-allowed;

    color: ${$("disabled.default")};
  }
`, s = m.div`
  display: inline-flex;

  ${(t) => h[t.$size]}

  > svg {
    width: inherit;
    height: inherit;
  }
`, H = f(
  function(l, d) {
    const {
      color: p = "brand",
      size: r = "md",
      variant: a = "contained",
      children: i,
      startAdornment: e,
      endAdornment: n,
      ...c
    } = l;
    return /* @__PURE__ */ o.jsxs(
      j,
      {
        ref: d,
        $color: p,
        $size: r,
        $variant: a,
        ...c,
        children: [
          e && /* @__PURE__ */ o.jsx(s, { $size: r, children: e }),
          typeof i == "string" ? /* @__PURE__ */ o.jsx(
            z,
            {
              color: "none",
              weight: "semibold",
              size: "none",
              children: i
            }
          ) : i,
          n && /* @__PURE__ */ o.jsx(s, { $size: r, children: n })
        ]
      }
    );
  }
);
export {
  H as default
};
