import { j as r } from "./jsx-runtime-NmMXPjeo.js";
import { ThemeProvider as s } from "styled-components";
import { DEFAULT_LIGHT_THEME as i } from "./components/Theme/themes.es.js";
import t from "./components/CssBaseline/index.es.js";
const d = (e) => {
  const {
    theme: o = i,
    children: m
  } = e;
  return /* @__PURE__ */ r.jsxs(s, { theme: o, children: [
    /* @__PURE__ */ r.jsx(t, {}),
    m
  ] });
};
export {
  d as T
};
