import { j as r } from "../../jsx-runtime-NmMXPjeo.js";
import n from "styled-components";
import { c as s } from "../../clsx.m-7d8NmkQq.js";
import "../Theme/themes.es.js";
import "../../palettes-foWluF5S.js";
import { getPaletteBorder as o } from "../Theme/utils.es.js";
import "react";
import "../CssBaseline/index.es.js";
import "../../radii-gpKo6wCW.js";
import "../../spacings-72DfHhtO.js";
import "../../widths-pYuCWRvl.js";
import "../../effects-OUwmcFls.js";
import "../Theme/zIndexes.es.js";
import "../../breakpoints-Ro4HX8Px.js";
import "../../polished.esm-wTGEn2h0.js";
import "../Theme/colors.es.js";
import "../../lookUpByPath-gjQB8xFz.js";
import "react-phone-number-input";
import "../CssBaseline/normalize.es.js";
import "../../styles-YtnfNTLW.js";
const m = "1024px", c = n.div`
  width: 100%;
  height: 100%;
  
  padding: 0;
  margin: 0;
    
  display: grid;
  
  grid-template-columns: 312px auto;
  grid-template-rows: 64px auto;
  grid-template-areas: "sidebar topbar" "sidebar content";

  @media (max-width: ${m}) {
    grid-template-columns: auto 56px;
    grid-template-rows: 64px auto;
    grid-template-areas: "topbar sidebar" "content content";
  }

  overflow: hidden;
  
  > div.sidebar-container {
    grid-area: sidebar;
    
    display: grid;

    overflow: hidden;

    border-right: 1px solid ${o("secondary")};
    
    box-sizing: border-box;

    @media (max-width: 1024px) {
      border-right: none;
      border-bottom: 1px solid ${o("secondary")};
      height: 64px;
    }
  }

  > div.topbar-container {
    grid-area: topbar;

    display: grid;

    overflow: hidden;

    border-bottom: 1px solid ${o("secondary")};
    
    height: 64px;

    box-sizing: border-box;
  }
  
  > div.content-container {
    grid-area: content;
    display: flex;
    overflow: auto;

    min-height: calc(100vh - 64px);
  }
`, I = ({ product: t, sidebar: i, topbar: e, content: a, className: d, ...p }) => /* @__PURE__ */ r.jsxs(c, { ...p, className: s(`product-${t}`, d), children: [
  /* @__PURE__ */ r.jsx("div", { className: "sidebar-container", children: i }),
  /* @__PURE__ */ r.jsx("div", { className: "topbar-container", children: e }),
  /* @__PURE__ */ r.jsx("div", { className: "content-container", children: a })
] });
export {
  I as default
};
